/**
 *
 * Toast
 *
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useInjectReducer } from 'redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import {
    selectToastMessage,
    selectToastLoader,
    selectToastType,
} from './selectors';

import { Loader } from '../../components/Loader';

import './styles.scss';
import { useEffect } from 'react';

export function Toast(props) {
    useInjectReducer({ key: sliceKey, reducer: reducer });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const toastMsg = useSelector(selectToastMessage);
    const type = useSelector(selectToastType);
    const loader = useSelector(selectToastLoader);

    const dispatch = useDispatch();

    useEffect(() => {
        if (toastMsg) {
            var instance = setTimeout(() => {
                dispatch(actions.removeToast());
            }, 3000);
        }
        return () => {
            clearTimeout(instance);
        };
    }, [toastMsg]);

    return (
        <>
            {toastMsg ? (
                <div data-testid="toast" className={`toast ${type}`}>
                    <div className="message">
                        <p>{toastMsg}</p>
                        {loader ? <Loader type={2} /> : null}
                    </div>
                </div>
            ) : null}
        </>
    );
}

export * from './notifier';
