import React, { useState, useEffect } from 'react';
import './styles.scss';

export const Info = props => {
    const [show, setShow] = useState(false);

    return (
        <span className="company-pool-information">
            <img onClick={() => setShow(true)} className="head-pool-info-icon" src="/static/images/information-fill.svg" />
            {show ?
                <span className="company-pool-info">Global credits are shared pool of company purchased credits which can be used by any associated recruiter <img className="pool-close-icon" onClick={() => setShow(false)} src="/static/images/pool-info-close.svg" /></span> : null
            }
        </span>
    );
};

export default Info;
