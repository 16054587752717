import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
})

export function submitRegister(data) {
    let baseUrl = process.env.REACT_APP_BASE_URL;
    if (typeof window !== 'undefined' && window.location.origin.includes('.tech')) {
        baseUrl = baseUrl.replace('.com', '.tech');
    }
    return instance.post(`${baseUrl}/recruiter/register`,data);
}
