import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = state => state.landing || initialState;

export const selectActionType = createSelector(
    [selectDomain],
    landingState => landingState.actionType,
);

export const selectLoginLoading = createSelector(
    [selectDomain],
    landingState => landingState.loginLoading,
);

export const selectLoginData = createSelector(
    [selectDomain],
    landingState => landingState.loginData,
);

export const selectLoginEmailError = createSelector(
    [selectDomain],
    landingState => landingState.loginEmailError,
);

export const selectLoginPasswordError = createSelector(
    [selectDomain],
    landingState => landingState.loginPasswordError,
);

export const selectLoginErrorStatus = createSelector(
    [selectDomain],
    landingState => landingState.loginErrorStatus,
);

export const selectForgotPasswordEmail = createSelector(
    [selectDomain],
    landingState => landingState.forgotPasswordEmail,
);

export const selectForgotPasswordLoading = createSelector(
    [selectDomain],
    landingState => landingState.forgotPasswordLoading,
);

export const selectForgotPasswordError = createSelector(
    [selectDomain],
    landingState => landingState.forgotPasswordError,
);

export const selectShowLoginForm = createSelector(
    [selectDomain],
    landingState => landingState.showLoginForm,
);

export const selectLinkSent = createSelector(
    [selectDomain],
    landingState => landingState.forgotPasswordLinkSent,
);

export const selectDomainCompanies = createSelector(
    [selectDomain],
    landingState => landingState.domainCompanies,
);

export const selectUnverifiedCompanies = createSelector(
    [selectDomain],
    landingState => landingState.unverifiedCompanies,
);

export const selectRegistrationDomain = createSelector(
    [selectDomain],
    landingState => landingState.registrationDomain,
);

export const selectRegistrationOrg = createSelector(
    [selectDomain],
    landingState => landingState.registrationOrg,
);

export const selectOrgFetchLoading = createSelector(
    [selectDomain],
    landingState => landingState.orgFetchLoading,
);

export const selectOrgFetchPageNumber = createSelector(
    [selectDomain],
    landingState => landingState.orgFetchPageNumber,
);

export const selectOrgFetchPageContent = createSelector(
    [selectDomain],
    landingState => landingState.orgFetchPageContent,
);

export const selectOrgFetchAllLoaded = createSelector(
    [selectDomain],
    landingState => landingState.orgFetchAllLoaded,
);

export const selectSuggestionsLoading = createSelector(
    [selectDomain],
    landingState => landingState.suggestionsLoading,
);

export const selectRegisterLoading = createSelector(
    [selectDomain],
    landingState => landingState.registerLoading,
);

export const selectRegisterData = createSelector(
    [selectDomain],
    landingState => landingState.registerData,
);

export const selectRegisterError = createSelector(
    [selectDomain],
    landingState => landingState.registerError,
);

export const selectCaptchaRequired = createSelector(
    [selectDomain],
    landingState => landingState.captchaRequired,
);

export const selectCaptchaToken = createSelector(
    [selectDomain],
    landingState => landingState.captchaToken,
);
