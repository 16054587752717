import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    selectDomainCompanies,
    selectRegisterError,
    selectRegisterLoading,
    selectUnverifiedCompanies,
} from '../../selectors';

import { Input } from '../../../../components/Input';
import { Dropdown } from '../../../../components/Dropdown';
import { CustomDropdown } from '../CustomDropdown';
import { Modal } from '../../../../components/Modal';
import { Button } from '../../../../components/Button';
import { PasswordValidationTooltip } from '../../../../components/PasswordValidationTooltip';

import { organizationLocation } from '../../../../../constants/organizationLocation';
import { checkPasswordValidations } from '../../../../../utils/checkPasswordValidations';
import passwordValidations from '../../../../../constants/passwordValidations';
import { checkPhone } from '../../../../../utils/checkPhone';
import { checkEmail } from '../../../../../utils/checkEmail';

import './styles.scss';
import { ubaInit } from '../../../../../utils/ubaInit';
import { ubaTracking } from '../../../../../utils/ubaTracking';
import { getQueryParams } from '../../../../../utils/getQueryParams';

export const registerErrorResponses = {
    missingName: 'Please enter your name',
    invalidName: 'Please enter a valid name shorter than 255 characters',
    missingEmail: 'Please enter email id',
    invalidEmail: 'Please enter a valid email id',
    missingPhone: 'Please enter phone number',
    invalidPhone: 'Please add a valid 10 digit number',
    missingDesignation: 'Please enter the designation',
    missingOrganisation: 'Please enter the organisation',
    missingLocation: 'Please select organization location',
    missingPassword: 'Please enter a password',
    missingConfirmPassword: 'Please confirm your password',
    passwordMismatch: 'The passwords you entered do not match',
    invalidPassword: 'Please enter a valid password',
    userFail: 'Email address does not exist',
    passwordFail: 'Incorrect password',
    missingParameters:
        'Oops! Our engineers will fix this shortly. Please try again after sometime.',
    serviceError:
        'Oops! Our engineers are working on fixing this, please try again after sometime.',
    duplicate: 'This email is already registered. Please login.',
    minLengthPassword: 'Password should be at least 6 characters',
    missingRecruiterType: 'Please select recruiter type',
    noInternet: 'Looks like you are not connected to the internet',
};

export const RegisterModal = props => {
    // contains form data to be submitted
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        designation: '',
        organisation: '',
        location: null,
        companyId: '',
        unverifiedCompanyId: '',
        password: '',
        confirmPassword: '',
        recruiterType: null,
    });

    let referral_title = null;
    let fetchedProps = getQueryParams(props.propsLocationSearch);
    referral_title = fetchedProps.referralId ? (fetchedProps.fromEmail ? `${decodeURIComponent(fetchedProps.fromEmail)} has invited you to join iimjobs` : "You’ve been invited to join iimjobs") : "";
    const [inputOrg, setInputOrg] = useState('');

    // variable to toggle password visibility
    const [hidePassword, setHidePassword] = useState(true);

    // variable to toggle password tooltip visibility
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

    // password validations i.e. valid or not
    const [validations, setValidations] = useState({});

    const [isMounted, setIsMounted] = useState(false);

    const passwordRef = useRef(null);
    const passwordInputRef = useRef(null);

    const registerError = useSelector(selectRegisterError);
    const domainCompanies = useSelector(selectDomainCompanies);
    const unverifiedCompanies = useSelector(selectUnverifiedCompanies);
    const isLoading = useSelector(selectRegisterLoading);

    const COMPANY_MIN_LENGTH = 2;

    useEffect(() => {
        var additionalParams = {
        }
        const params = getQueryParams(props.propsLocationSearch);
        if (params.ref) {
            additionalParams["ref"] = params.ref
        }      

        ubaInit('recruiterSignup');
        ubaTracking('recruiterSignup', 'pageView', additionalParams)
    }, [])

    // using this in settimeout to check if component has been demounted
    useEffect(() => {
        setIsMounted(true);

        return () => setIsMounted(false);
    }, []);

    // set the cursor to the end of the text when visibility is toggled.
    useEffect(() => {
        if (passwordInputRef && passwordInputRef.current) {
            const val = passwordInputRef.current.value.length;
            passwordInputRef.current.setSelectionRange(val, val);
        }
    }, [hidePassword]);

    const setFormData = (key, value) => {
        setData(prevValue => {
            const newValue = {
                ...prevValue,
            };
            newValue[key] = value;
            return newValue;
        });
    };

    const onPasswordFocusHandler = () => {
        setShowPasswordTooltip(true);
    };

    const onPasswordBlurHandler = () => {
        setShowPasswordTooltip(false);
    };

    const onPasswordChangeHandler = event => {
        setFormData('password', event.target.value);
        const validations = checkPasswordValidations(event.target.value);
        props.setError('password', null);
        setValidations(validations);
    };

    const handleOnOrgSelected = data => {
        setFormData('organisation', data.name);
        setInputOrg(data.name);
        props.setError('organisation', null);

        if (data.id && data.companyId) {
            setFormData('companyId', data.companyId);
            setFormData('unverifiedCompanyId', data.id);
        } else {
            setFormData('companyId', '');
            setFormData('unverifiedCompanyId', '');
        }
    };

    const onKeyDownHandler = event => {
        if (event.keyCode === 13) {
            onSubmitHandler();
        }
    };

    // fetch list of suggested organisations based on user domain
    const onEmailBlur = event => {
        if (!data.email) {
            return;
        }

        const domain = data.email.split('@')[1];
        props.onFetchDomainCompanies(domain);
    };

    const validateData = () => {
        let hasError = false;
        const errorObj = {};
        props.setError();

        if (!data.name || !data.name.trim()) {
            hasError = true;
            errorObj.name = registerErrorResponses.missingName;
        } else if (data.name && data.name.length >= 255) {
            hasError = true;
            errorObj.name = registerErrorResponses.invalidName;
        }

        if (!data.email || !data.email.trim()) {
            hasError = true;
            errorObj.email = registerErrorResponses.missingEmail;
        }

        if (!checkEmail(data.email)) {
            hasError = true;
            errorObj.email = registerErrorResponses.invalidEmail;
        }

        if (!data.phone || !data.phone.trim()) {
            hasError = true;
            errorObj.phone = registerErrorResponses.missingPhone;
        }

        if (!checkPhone(data.phone) || data.phone.length !== 10) {
            hasError = true;
            errorObj.phone = registerErrorResponses.invalidPhone;
        }

        if (!data.designation || !data.designation.trim()) {
            hasError = true;
            errorObj.designation = registerErrorResponses.missingDesignation;
        }

        if (!data.organisation || !data.organisation.trim()) {
            hasError = true;
            errorObj.organisation = registerErrorResponses.missingOrganisation;
        }

        if (!data.location || data.location === '0' || !data.location.trim()) {
            hasError = true;
            errorObj.location = registerErrorResponses.missingLocation;
        }

        if (!data.password || !data.password.trim()) {
            hasError = true;
            errorObj.password = registerErrorResponses.missingPassword;
        }

        if (!data.confirmPassword || !data.confirmPassword.trim()) {
            hasError = true;
            errorObj.confirmPassword =
                registerErrorResponses.missingConfirmPassword;
        }

        const passwordErrors = checkPasswordValidations(data.password, true);
        let allValid = true,
            firstErrorCode = 0;
        Object.keys(passwordErrors).map(key => {
            if (passwordErrors[key] !== 1 && allValid) {
                allValid = false;
                firstErrorCode = key;
            }
        });

        if (!allValid) {
            setValidations(passwordErrors);
            setShowPasswordTooltip(true);
            const error = passwordValidations.find(item => {
                if (item.id === parseInt(firstErrorCode)) {
                    return true;
                }
            });
            errorObj.password = error.err;
        }

        if (data.password !== data.confirmPassword) {
            hasError = true;
            errorObj.confirmPassword = registerErrorResponses.passwordMismatch;
        }

        if (!data.recruiterType) {
            hasError = true;
            errorObj.recruiterType =
                registerErrorResponses.missingRecruiterType;
        }

        if (hasError) {
            props.setError(null, errorObj);

            if (errorObj.password && passwordRef.current) {
                passwordRef.current.focus();
            }
            return false;
        }

        return true;
    };

    const onSubmitHandler = () => {
        if (validateData()) {
            props.onUserRegister({
                name: data.name,
                email: data.email,
                phone: data.phone,
                designation: data.designation,
                organisation: data.organisation,
                location: data.location,
                companyId: data.companyId ? data.companyId : '',
                unverifiedCompanyId: data.unverifiedCompanyId
                    ? data.unverifiedCompanyId
                    : '',
                password: data.password,
                confirmPassword: data.confirmPassword,
                type: data.recruiterType,
            });
        }
    };

    useEffect(() => {
        if (!isMounted) {
            return;
        }

        // DEBOUNCING FOR FETCH ORGANISATIONS API CALL
        const timerID = setTimeout(() => {
            if (inputOrg && inputOrg.length >= COMPANY_MIN_LENGTH) {
                props.onOrganisationChange(inputOrg);
            }
        }, 300);

        return () => clearTimeout(timerID);
    }, [inputOrg]);

    return (
        <Modal
            classes={['userRegisterModal']}
            onClose={props.onClose}
            styleType="new"
            modalOptions={{
                'data-testid': 'userRegisterModal',
            }}
        >
            <div className="register__wrapper">
                <div className="register__container">
                    <div className="register__container--header">
                        <div>
                            {referral_title && <div className="referral_title">{referral_title}</div>}
                            <div className="title">Post a job</div>
                            <div className="subtitle">
                                Register, post a job and easily find great
                                talent for your company or clients!
                            </div>
                        </div>
                        <div className="r-modal-close-responsive">
                            <img
                                src="/static/images/close.png"
                                alt="close"
                                onClick={props.onClose}
                            />
                        </div>
                    </div>

                    <div className="register__container--body">
                        <div className="company-type">
                            <div className="title">
                                Choose your Company Type
                            </div>
                            <div className="btn-container">
                                <div
                                    className={`btn-company ${
                                        data.recruiterType === 1 ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        props.setError('recruiterType', null);
                                        setFormData(
                                            'recruiterType',
                                            data.recruiterType === 1 ? null : 1,
                                        );
                                    }}
                                    data-testid="btn-company"
                                >
                                    <div
                                        style={{
                                            backgroundImage:
                                                data.recruiterType === 1
                                                    ? "url('/static/images/company-green.png')"
                                                    : "url('/static/images/company.png')",
                                        }}
                                    >
                                        I am a Company
                                    </div>
                                </div>
                                <div
                                    className={`btn-consultant ${
                                        data.recruiterType === 2 ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        props.setError('recruiterType', null);
                                        setFormData(
                                            'recruiterType',
                                            data.recruiterType === 2 ? null : 2,
                                        );
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundImage:
                                                data.recruiterType === 2
                                                    ? "url('/static/images/consultant-green.png')"
                                                    : "url('/static/images/consultant.png')",
                                        }}
                                    >
                                        I am a Consultant
                                    </div>
                                </div>
                            </div>
                            {registerError.recruiterType ? (
                                <div
                                    className="error error-type"
                                    data-testid="input-type-error"
                                >
                                    {registerError.recruiterType}
                                </div>
                            ) : null}
                        </div>
                        <div className="form__container">
                            <div className="form__container--row">
                                <div className="formgroup">
                                    <Input
                                        name="name"
                                        type="text"
                                        className="input-name"
                                        id="input_name"
                                        placeholder="Write your full name"
                                        label="Name"
                                        value={data.name}
                                        onChange={event => {
                                            setFormData(
                                                'name',
                                                event.target.value,
                                            );
                                            props.setError('name', null);
                                        }}
                                        styleType="new"
                                        error={
                                            registerError.name
                                                ? registerError.name
                                                : null
                                        }
                                        errorOptions={{
                                            'data-testid': 'input-name-error',
                                        }}
                                        inputProps={{
                                            onKeyDown: onKeyDownHandler,
                                        }}
                                    />
                                </div>
                                <div className="formgroup dropdown">
                                    <label>Location</label>
                                    <Dropdown
                                        options={organizationLocation}
                                        onChange={(value, ev) => {
                                            if (value) {
                                                props.setError(
                                                    'location',
                                                    null,
                                                );
                                            }
                                            setFormData('location', value);
                                        }}
                                        styleType="new"
                                        error={registerError.location}
                                        selectProps={{
                                            'data-testid': 'input-location',
                                        }}
                                    />
                                    {registerError.location ? (
                                        <div
                                            className="error error-location"
                                            data-testid="input-location-error"
                                        >
                                            {registerError.location}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form__container--row">
                                <div className="formgroup">
                                    <Input
                                        name="phone"
                                        type="text"
                                        className="input-phone"
                                        id="input_phone"
                                        placeholder="e.g. 9873721034"
                                        label="Phone"
                                        value={data.phone}
                                        onChange={event => {
                                            setFormData(
                                                'phone',
                                                event.target.value,
                                            );
                                            props.setError('phone', null);
                                        }}
                                        inputProps={{
                                            onKeyDown: onKeyDownHandler,
                                            'data-testid': 'input-phone',
                                        }}
                                        styleType="new"
                                        error={
                                            registerError.phone
                                                ? registerError.phone
                                                : null
                                        }
                                        errorOptions={{
                                            'data-testid': 'input-phone-error',
                                        }}
                                    />
                                </div>
                                <div className="formgroup">
                                    <Input
                                        name="email"
                                        type="text"
                                        className="input-email"
                                        id="input_email"
                                        placeholder="Your official email id (not gmail/yahoo)"
                                        label="Work email id"
                                        value={data.email}
                                        onChange={event => {
                                            setFormData(
                                                'email',
                                                event.target.value,
                                            );
                                            props.setError('email', null);
                                        }}
                                        inputProps={{
                                            onKeyDown: onKeyDownHandler,
                                            onBlur: onEmailBlur,
                                        }}
                                        styleType="new"
                                        error={
                                            registerError.email
                                                ? registerError.email
                                                : null
                                        }
                                        errorOptions={{
                                            'data-testid': 'input-email-error',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form__container--row">
                                <div className="formgroup">
                                    <CustomDropdown
                                        label="Organization"
                                        value={data.organisation}
                                        inputValue={inputOrg}
                                        suggestedOptions={domainCompanies}
                                        options={unverifiedCompanies}
                                        loadMore={props.loadMoreSuggestions}
                                        onInputChange={value => {
                                            setInputOrg(value);

                                            // when user clears the input, clear suggestion cache
                                            if (!value) {
                                                props.onOrganisationChange();
                                            }
                                        }}
                                        onOptionSelected={handleOnOrgSelected}
                                        error={registerError.organisation}
                                    />
                                    {registerError.organisation ? (
                                        <div
                                            className="error error-organisation"
                                            data-testid="input-organisation-error"
                                        >
                                            {registerError.organisation}
                                        </div>
                                    ) : null}
                                </div>

                                <div className="formgroup designation">
                                    <Input
                                        name="designation"
                                        type="text"
                                        className="input-designation"
                                        id="input_designation"
                                        placeholder="e.g. Talent Acquisition Manager"
                                        label="Designation"
                                        value={data.designation}
                                        onChange={event => {
                                            setFormData(
                                                'designation',
                                                event.target.value,
                                            );
                                            props.setError('designation', null);
                                        }}
                                        inputProps={{
                                            onKeyDown: onKeyDownHandler,
                                        }}
                                        styleType="new"
                                        error={
                                            registerError.designation
                                                ? registerError.designation
                                                : null
                                        }
                                        errorOptions={{
                                            'data-testid':
                                                'input-designation-error',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form__container--row">
                                <div className="formgroup password">
                                    <Input
                                        name="password"
                                        type="password"
                                        className="input-password"
                                        placeholder="Set a password"
                                        label="Password"
                                        value={data.password}
                                        onChange={onPasswordChangeHandler}
                                        styleType="new"
                                        error={
                                            registerError.password
                                                ? registerError.password
                                                : null
                                        }
                                        errorOptions={{
                                            'data-testid':
                                                'input-password-error',
                                        }}
                                        inputProps={{
                                            ref: passwordRef,
                                            onFocus: onPasswordFocusHandler,
                                            onBlur: onPasswordBlurHandler,
                                            onKeyDown: onKeyDownHandler,
                                        }}
                                    />

                                    <PasswordValidationTooltip
                                        validations={validations}
                                        isVisible={showPasswordTooltip}
                                    />
                                </div>
                                <div className="formgroup confirm-password">
                                    <Input
                                        name="confirm-password"
                                        type={`${
                                            hidePassword ? 'password' : 'text'
                                        }`}
                                        className="input-confirm-password"
                                        id="input_confirm_password"
                                        placeholder="Re-enter password"
                                        label="Confirm Password"
                                        value={data.confirmPassword}
                                        onChange={event => {
                                            setFormData(
                                                'confirmPassword',
                                                event.target.value,
                                            );
                                            props.setError(
                                                'confirmPassword',
                                                null,
                                            );
                                        }}
                                        errorOptions={{
                                            'data-testid':
                                                'input-conf-pass-error',
                                        }}
                                        inputProps={{
                                            ref: passwordInputRef,
                                            onKeyDown: onKeyDownHandler,
                                        }}
                                        styleType="new"
                                        error={
                                            registerError.confirmPassword
                                                ? registerError.confirmPassword
                                                : null
                                        }
                                    >
                                        <div className="password_options">
                                            {hidePassword ? (
                                                <img
                                                    className="icon-lock"
                                                    src="/static/images/lock.svg"
                                                    alt="password hidden"
                                                    data-testid="icon-lock"
                                                    onClick={() => {
                                                        if (
                                                            passwordInputRef.current
                                                        ) {
                                                            passwordInputRef.current.focus();
                                                        }
                                                        setHidePassword(false);
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    className="icon-unlock"
                                                    src="/static/images/unlock.svg"
                                                    alt="password visible"
                                                    data-testid="icon-unlock"
                                                    onClick={() => {
                                                        if (
                                                            passwordInputRef.current
                                                        ) {
                                                            passwordInputRef.current.focus();
                                                        }
                                                        setHidePassword(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Input>
                                </div>
                            </div>
                        </div>
                        <Button
                            type="btn-register"
                            onClick={onSubmitHandler}
                            isLoading={isLoading}
                            dataTestid="btn-register-user"
                        >
                            Register
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

RegisterModal.propTypes = {
    onClose: PropTypes.func, // function to close modal
    setError: PropTypes.func, // function to set errors in register form
    loadMoreSuggestions: PropTypes.func, // handler to load suggestions for organisation dropdown
    onOrganisationChange: PropTypes.func, // triggers saga to fetch organisation suggestions
    onUserRegister: PropTypes.func, // user registration handler
};
