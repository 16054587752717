import { getQueryParams } from './getQueryParams';

export function removeQueryParams(params) {
    const existingParams = getQueryParams(window.location.search);
    let newUrl = `${window.location.origin}${window.location.pathname}`;
    let newParams = [];
    for (let key of Object.keys(existingParams)) {
        if (!params.includes(key)) {
            // console.log(key);
            newParams.push(`${key}=${existingParams[key]}`);
        }
    }
    // console.log(newParams, newParams.length);
    if (newParams.length) {
        newUrl += '?' + newParams.join('&');
    }
    // console.log(newUrl);
    window.history.replaceState('object or string', 'Title', newUrl);
}
