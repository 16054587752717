import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = state => state.app || initialState;

export const selectProfile = createSelector(
    [selectDomain],
    appState => appState.profile,
);

export const selectRecruiterId = createSelector(
    [selectDomain],
    appState => appState.recruiterId,
);

export const selectEventMap = createSelector(
    [selectDomain],
    appState => appState.eventMap,
);

export const selectFeature = createSelector(
    [selectDomain],
    appState => feature => Boolean(+appState.features[feature]),
);

export const selectPostJobVersion = createSelector(
    [selectDomain],
    appState => appState.postJobVersion,
);
