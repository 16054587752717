import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { LoginForm } from '../LoginForm';
import { ResetPasswordForm } from '../ResetPasswordForm';
import { selectShowLoginForm } from '../../selectors';

import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

export const RemarkablePoint = props => {
    const { mainText, subText, imageUrl } = props;

    return (
        <div className="remarkable-point-container">
            <div className="point-image">
                <img src={imageUrl} alt="" />
            </div>
            <div className="point-text">
                <div className="point-main-text">{mainText}</div>
                <div className="point-sub-text">{subText}</div>
            </div>
        </div>
    );
};

export const Introduction = props => {
    const showLoginForm = useSelector(selectShowLoginForm);

    return (
        <div className="introduction__container">
            <div className="introduction__container--header">
                <div className="tagline">
                    {process.env.REACT_APP_BASE_DOMAIN === 'hirist' ? (
                        <div>
                            {landingConfig.loginsection.heading}{' '}
                            <img
                                className="icon-love"
                                src="/static/images/heart.png"
                                alt="love"
                            />{' '}
                            {landingConfig.loginsection.subheading}
                        </div>
                    ) : null}
                </div>
                <div className="title">
                    {process.env.REACT_APP_BASE_DOMAIN === 'hirist' ? (
                        <>
                            Hire great <span>tech</span> talent
                        </>
                    ) : (
                        <div className="title-iimjobs">
                            <p>{landingConfig.loginsection.heading}</p>
                            <div className="title-iimjobs-img">
                                <div className="height-slab"></div>
                                <img
                                    className="icon-love"
                                    src="/static/images/heart-large.png"
                                    alt="love"
                                />
                            </div>
                            <p>{landingConfig.loginsection.subheading}</p>
                        </div>
                    )}
                </div>
                <div className="subtitle">
                    {landingConfig.section0.subtitle}
                </div>
                <div className="remarkable-points-container">
                    {[0, 1].map(value => (
                        <RemarkablePoint
                            mainText={
                                landingConfig.remarkablePoints[value].mainText
                            }
                            subText={
                                landingConfig.remarkablePoints[value].subText
                            }
                            imageUrl={
                                landingConfig.remarkablePoints[value].imageUrl
                            }
                        />
                    ))}
                </div>
            </div>
            {showLoginForm ? (
                <LoginForm
                    setLoginErrors={props.setLoginErrors}
                    onLogin={props.onLogin}
                    onRegister={props.onRegister}
                    onForgotPassword={() => props.toggleLoginForm(false)}
                    handleCaptchaSubmit={props.handleCaptchaSubmit}
                />
            ) : (
                <ResetPasswordForm
                    onForgotPasswordError={props.onForgotPasswordError}
                    onForgotPassword={props.onForgotPassword}
                    onBackPressed={() => props.toggleLoginForm(true)}
                    handleCaptchaSubmit={props.handleCaptchaSubmit}
                />
            )}
        </div>
    );
};

Introduction.propTypes = {
    toggleLoginForm: PropTypes.func, // function to toggle between the login form and the reset password form
    setLoginErrors: PropTypes.func, // function to set set errors in the login form
    onLogin: PropTypes.func, // login api call handler
    onRegister: PropTypes.func, // register handler
    onForgotPassword: PropTypes.func, // forgot password api call handler
    onForgotPasswordError: PropTypes.func, // function to set errors in forgot password form
};
