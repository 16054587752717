import React from 'react';
import PropTypes from 'prop-types';

import passwordValidations from '../../../constants/passwordValidations';
import './styles.scss';

export const PasswordValidationTooltip = props => {
    const validationList = passwordValidations.map(item => {
        const isValid = props.validations[item.id] || 0;

        return (
            <li
                key={item.id}
                className="password-validation"
                data-id={item.id}
                data-passed={isValid}
            >
                <span
                    className={`b validation-icon ${
                        isValid === 1
                            ? 'tick'
                            : isValid === 2
                            ? 'close'
                            : 'bullet'
                    }`}
                ></span>
                <span className="t validation-text">{item.validation}</span>
            </li>
        );
    });

    return (
        <>
            {props.isVisible ? (
                <div className="password-validations-tooltip">
                    <span className="title">Your password must include:</span>
                    <div className="arrow"></div>
                    <ul
                        id="password-validations"
                        className="password-validations"
                    >
                        {validationList}
                    </ul>
                </div>
            ) : null}
        </>
    );
};

PasswordValidationTooltip.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    validations: PropTypes.object.isRequired,
};
