import React, { useEffect, useState } from 'react'
import { Button } from '../Button'
import './styles.scss'
import { Input } from '../Input'
import moment from 'moment';
import { postCSATReview } from '../../../services/postCSATReview';
import { fetchCSATReview } from '../../../services/fetchCSATReview';

const satisfyMap = {
    1: "Very Dissatisfied",
    2: "Dissatisfied",
    3: "Neutral",
    4: "Satisfied",
    5: "Very Satisfied"
}

const reasonMap = {
    1: 'Relevancy of Candidates',
    2: 'Number of Applications',
    3: 'Recommended Candidates on Premium Posting',
    4: 'Overall Job Posting Experience',
    5: 'Any other'
}

const CSATSurvey = (props) => {

    const [satisfactionLevel, setSatisfactionLevel] = useState(0);
    const [currentState,setCurrentState] = useState(1);
    const [reasons, setReasons] = useState([]);
    const [otherReason, setOtherReason] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [hasSlideIn, setHasSlideIn] = useState(false); 



    useEffect(() => {
        const timer = setTimeout(() => { 
            try {
              if (typeof localStorage !== 'undefined') {
                const surveyClosedTimestamp = localStorage.getItem('csatSurveyClosed');
                
                if (!surveyClosedTimestamp) {
                  fetchSurveyData(); // No timestamp means the survey hasn't been closed yet
                } else {
                  const savedTime = moment(surveyClosedTimestamp); // Parse the timestamp from localStorage
                  const currentTime = moment(); // Get the current time as a Moment object
                  const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      
                  // Compare if the saved timestamp is older than 24 hours
                  if (currentTime.diff(savedTime) > oneDayInMs) {
                    fetchSurveyData(); // Make the API call if it's older than 24 hours
                  }
                }
              } else {
                console.error('localStorage is not available');
              }
            } catch (error) {
              console.error('Error checking localStorage:', error);
            }
          }, 10000); // 30 seconds delay
          
          return () => clearTimeout(timer);

    }, []);

    useEffect(() => {
        if (showModal && !hasSlideIn) {
            setHasSlideIn(true);  // Mark that the survey has been shown
        }
    }, [showModal, hasSlideIn]);

    const fetchSurveyData = async () => {
        try {

            const data = await fetchCSATReview(props.recruiterId);

           if(!data.data.data.length)
                    setShowModal(true)
          
          
        } catch (error) {
          console.error('Error fetching survey data:', error);
        }
      };


    const onClickSubmit = async () => {

        if(satisfactionLevel <= 3 && currentState < 2){
            setCurrentState(2);
            return;
        }

        const reasonText = reasons.map(reason => reasonMap[reason]);
        if(otherReason) reasonText.push(otherReason);

        const data = {
            satisfactionLevel,
            reasons: reasonText
        }

        await postCSATReview(props.recruiterId, data)

        setCurrentState(3);
    }

    const onSelectReason = (num) => {

        let selectedReasons = [...reasons];

        if(reasons.includes(num)) {
            selectedReasons = selectedReasons.filter(reason => reason != num);
        }
        else {
            selectedReasons.push(num);
        }

        setReasons(selectedReasons);
    }

    const onOtherReasonChange = (e) => {
        setOtherReason(e.target.value);
    }

   const oncloseSurvey = () => {
        setShowModal(false);
        const now = moment(); // Get the current time using Moment.js
        localStorage.setItem('csatSurveyClosed', now.toISOString());
    };


    const isDisabled = () => {

        return !satisfactionLevel || (currentState == 2 && (!reasons.length || (reasons.includes("5") && !otherReason))) ? true : false
    
    }





    return (
        
       showModal ? (

        <div className='theme-light'>

            <div className={`csat-survey-container ${hasSlideIn ? 'slide-in' : ''}`}>
                <div className='csat-heading'>
                    {currentState < 3 ? <p>Help us improve </p> : <p>Response Submitted</p>}
                    
                    <i onClick={oncloseSurvey} className="icon-cross_icon"></i>

                </div>
                <div className='csat-hr'></div>

                {

                    currentState == 1 ? (
                        <>
                            <p className='csat-sub-heading'>How satisfied are you with overall experience on {process.env.REACT_APP_BASE_DOMAIN_NAME} recruiter portal?</p>

                            <div className='csat-satisfaction'>
                            {
                            Object.keys(reasonMap).map((num, index) => ( 
                                <div
                                key={index}
                                className={`csat-satisfy-level ${num == 5 ? 'last-level' : ''}`}
                                onClick={() => setSatisfactionLevel(parseInt(num, 10))} // Update state when clicked
                                >
                                <img
                                    className={`csat-satisfy-icon ${satisfactionLevel === parseInt(num, 10) ? 'reason-selected' : ''}`}
                                    src={`/static/images/satisfy${num}.svg`}
                                    alt={`Satisfaction level ${num}`}
                                />
                                <p
                                    className={`csat-satisfy-name ${satisfactionLevel === parseInt(num, 10) ? 'reason-selected' : ''} ${num == 1 || num == 5 ? '' : 'hide-level'}`}
                                >
                                    {satisfyMap[num]}
                                </p>
                                </div>
                            ))
                            }

                            </div>
                        </>
                    ) : null
                }

                {

                currentState == 2 ? (
                    <>
                        <p className='csat-sub-heading'>Where do you think we can make improvements?</p>

                        <div className={`csat-reason-container ${reasons.includes("5") ? 'any-other-selected' : ''}`}>
                        {
                            Object.keys(reasonMap).map((num, index) => ( 
                                <div
                                    key={index}
                                    className={`csat-reason ${reasons.includes(num) ? 'reason-selected' : ''}`}
                                    onClick={() => onSelectReason(num)} // Update state when clicked
                                >
                                <img
                                    className='csat-reason-icon'
                                    src={`/static/images/${!reasons.includes(num) ? 'plus-icon' : 'check-white'}.svg`}
                                    alt={`Satisfaction level ${num}`}
                                />
                                <p
                                    className={`csat-reason-name`}
                                >
                                    {reasonMap[num]}
                                </p>
                                </div>
                            ))
                        }

                        </div>

                        {
                            reasons.includes("5") ? (

                                <Input
                                type="text"
                                value={otherReason}
                                className='csat-otherreason'
                                id="otherReason"
                                onChange={onOtherReasonChange}
                                name="otherReason"
                                inputProps={{
                                    disabled: !reasons.includes("5") 
                                }}
                            ></Input>

                            ) : null
                        }

                    </>
                ) : null
                }

                {

                currentState == 3 ? (
                    <div className='csat-thanks'>

                        <img
                            src="/static/images/check.svg"
                            alt=""
                            className="img-check"
                        />


                        <p className='csat-thanks-main'>Thank you for your feedback!</p>

                        <p className='csat-thanks-sub'> Your feedback is valuable in helping us enhance your experience.</p>


                        
                    </div>
                ) : null
                }
            
                {
                    currentState == 1 || currentState == 2 ? (
                        <Button classes={['csat-submit-btn', isDisabled() ? 'disabled' : '']} isLoading={false} onClick={onClickSubmit} type="green" btnProps={{disabled: isDisabled()}}>{currentState == 1 ? 'Proceed' : 'Submit'}</Button>

                    ) : null
                }

            </div>

        </div>

        
    

       ) : null
    )
}

export default CSATSurvey