import React from 'react';
import { useEffect } from 'react';

import './styles.scss';

const SecurityModal = ({ submit }) => {
    useEffect(() => {
        
        document.body.style = 'overflow:hidden';
        grecaptcha.render('re-captcha', {
            sitekey: window.reCaptchaKey,
            callback: onSubmit,
        });
        
        return () => {
            document.body.style = 'overflow:auto';
        };
    }, []);

    function onSubmit(token) {
        submit(token);
    }

    return (
        <div className="security-modal">
            <div className="security-modal-body">
                <div className="heading">Security Check</div>
                <div className="description">
                    Our system has detected unusual traffic from your computer
                    network. This step is to see if it's really you sending the
                    request and not a robot.
                </div>
                <div id="re-captcha" className="re-captcha"></div>
            </div>
        </div>
    );
};

export default SecurityModal;
