import React from 'react';
import { Toast } from '../../app/containers/Toast';
import { Landing } from '../../app/containers/Landing';
import { Header } from '../../app/components/Header';
import { Footer } from '../../app/components/Footer';
import { checkFeature } from '../../utils/checkFeature';

import '../../app/styles.scss';

export function App() {
    return (
        <div className="app theme-light">
            <Toast />
            <Header useNewLandingDesign={true} checkFeature={checkFeature} />
            <Landing />
            <Footer useNewLandingDesign={true} />
        </div>
    );
}
