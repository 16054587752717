import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from './app';
import store from '../store';

const MOUNT_NODE = document.getElementById('root');

const ConnectedApp = ({ Component }) => (
    <Provider store={store}>
        <Component />
    </Provider>
);
const render = Component => {
    ReactDOM.hydrate(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

render(App);
