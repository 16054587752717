/**
 *
 * Input
 *
 */
import React from 'react';
import { FieldError } from '../FieldError';
import propTypes from 'prop-types';
import './styles.scss';

export function Input(props) {
    const styleType = props.styleType || 'original';

    return (
        <div className={`r-input ${styleType} ${props.className}`}>
            {   !(props.dontUseLabel && props.dontUseLabel == true) && 
                <label htmlFor={props.id}>{props.label}</label>
            }
            <div className="r-input-wrapper">
                <input
                    className={`${props.error ? 'on-error' : ''} ${
                        props.icon ? 'with-icon' : ''
                    }`}
                    value={props.value}
                    onChange={props.onChange}
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    placeholder={props.placeholder}
                    min={props.min}
                    {...props.inputProps}
                />
                {props.children ? props.children : null}
                {props.icon ? (
                    <span className="r-icon">{props.icon}</span>
                ) : null}
            </div>
            {props.error ? (
                <FieldError options={props.errorOptions}>
                    {props.error}
                </FieldError>
            ) : null}
        </div>
    );
}

Input.propTypes = {
    className: propTypes.string,
    id: propTypes.string.isRequired,
    value: propTypes.string,
    type: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    placeholder: propTypes.string.isRequired,
    onChange: propTypes.func,
    styleType: propTypes.string,
    errorOptions: propTypes.object,
};
