const passwordValidations = [
    {
        id: 1,
        validation: 'One lowercase character',
        err: 'Password must contain at least one lowercase letter.',
    },
    {
        id: 2,
        validation: 'One uppercase character',
        err: 'Password must contain at least one uppercase letter.',
    },
    {
        id: 3,
        validation: 'One number',
        err: 'Password must contain at least one digit.',
    },
    {
        id: 4,
        validation: 'One special character from @#$%^&*!()_+|~-=`{}:";\'<>/',
        err:
            'Password must contain at least one symbol from @#$%^&*!()_+|~-=`{}:";\'<>/',
    },
    {
        id: 5,
        validation: '8 characters minimum',
        err: 'Password must be at least 8 characters long',
    },
];

export default passwordValidations;