import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = state => state.toast || initialState;

export const selectToastMessage = createSelector(
    [selectDomain],
    toastState => toastState.toastMessage,
);

export const selectToastLoader = createSelector(
    [selectDomain],
    toastState => toastState.loading,
);

export const selectToastType = createSelector(
    [selectDomain],
    toastState => toastState.type,
);
