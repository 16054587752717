import moment from "moment";

export function getTitleFormat(title) {
    return title.replace(/\(\d+-\d+ \w+\)$/, '');
}

export function setJobStatus(aData) {
    const baseDomain = process.env.REACT_APP_BASE_DOMAIN;
    const baseDomainName = process.env.REACT_APP_BASE_DOMAIN_NAME;
    var obj = {
        status: aData['status'],
        message: 'Nothing to show',
        statusMsg: true,
        actions: false,
        extraStatus: false,
        extraStatusMsg: 'But updated',
    };
    switch (aData['status']) {
        case 'rejected':
            obj['status'] = 'Not Published';
            obj['message'] = aData['message'];
            obj['statusMsg'] = false;
            obj['statusDesc'] = aData['message'];
            obj.questionText = 'Why was this job not published?';
            if (
                aData['message'].includes(
                    'Please provide more details about the role & responsibilities/profile.',
                )
            ) {
                obj.statusDesc =
                    'The content currently provided in the JD is insufficient for us to publish the job. Please provide more details about the role & responsibilities/profile, after which our team will review and publish the job.';
                obj.questionText = 'Why is this job on hold?';
                obj['status'] = 'On hold';
                obj.btnText = 'Add roles & responsibilites > ';
                obj.redirectUrl = '/job/' + aData['id'] + '/edit?ref=mdrejdash';
            }

            if (
                aData['message'].includes(
                    'How much is the average salary offered for this position',
                )
            ) {
                obj.statusDesc =
                    'Please update the average salary offered for this role on your job posting. The salary will not be shown to the candidates and is only internal moderation.';
                obj.questionText = 'Why is this job on hold?';
                obj['status'] = 'On hold';
                obj.btnText = 'Update Salary > ';
                obj.redirectUrl = '/job/' + aData['id'] + '/edit?ref=sorejdash';
            }

            if (
                aData['message'].includes('There is no content/JD available.')
            ) {
                obj.statusDesc =
                    'There is no content currently provided in the JD. Please provide more details about the role & responsibilities/profile, after which our team will review and publish the job.';
                obj.questionText = 'Why is this job on hold?';
                obj['status'] = 'On hold';
                obj.btnText = 'Add roles & responsibilites > ';
                obj.redirectUrl = '/job/' + aData['id'] + '/edit?ref=ncrejdash';
            }

            if (
                aData['message'].includes(
                    "We generally don't allow job postings from Gmail/Yahoo/Outlook etc ids.",
                )
            ) {
                obj.questionText = 'Why is this job on hold?';
                obj.statusDesc =
                    'We generally do not allow job postings from Gmail/Yahoo/Outlook etc ids. This is to ensure credibility of posting on the portal.\n\nIn case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@' +
                    baseDomainName +
                    "including your contact number. After this, we'll review and publish the job.";
                obj['status'] = 'On hold';
            }

            if (
                aData['message'].includes(
                    "We generally do not allow job postings from Gmail/Yahoo/Outlook etc ids.",
                )
            ) {
                obj.questionText = 'Why is this job on hold?';
                obj.statusDesc =
                    'We generally do not allow job postings from Gmail/Yahoo/Outlook etc ids. This is to ensure credibility of posting on the portal.\n\nIn case you intend to use your personal mail ID only for posting jobs, please send us a test mail from your official mail ID at info@' +
                    baseDomainName +
                    "including your contact number.Also, please provide more details about the role & responsibilities as the content currently provided in the JD is insufficient.\n\n Once you do this, our team will review and publish the job.";
                obj['status'] = 'On hold';
            }

            if (
                aData['message'].includes(
                    "Kindly post all the roles separately with proper job descriptions.",
                )
            ) {
                obj.questionText = 'Why is this job on hold?';
                obj.statusDesc =
                    "We noticed that you have posted the same job with different job descriptions. Please post all the roles separately with a proper job title and matching job description.";
                obj['status'] = 'On hold';
            }

            if (
                aData['message'].includes(
                    "The job description and title do not match. Please update the job description.",
                )
            ) {
                obj.questionText = 'Why is this job on hold?';
                obj.statusDesc =
                    "The content currently provided in the JD does not match with the job title. Please provide a JD that matches with the job title or update the job title. Once you do this, our team will review and publish the job.";
                obj['status'] = 'On hold';
            }


            if (
                aData['message'].includes(
                    'Please post this job on updazz.com',
                ) ||
                aData['message'].includes(
                    'Please post this job on biojoby.com',
                ) ||
                aData['message'].includes(
                    'Please post this job on engineeristic.com',
                ) ||
                aData['message'].includes('Please post all technical jobs')
            ) {
                var statusDesc =
                    'This job could not be published on iimjobs.com as it is a job portal for mid-senior/senior level roles and you will not find the right candidates here for this role.\n\n';
                obj.questionText = 'What does this status mean?';

                if (baseDomain === 'hirist') {
                    statusDesc =
                        'This job could not be published on hirist.tech as it is a job portal for IT/Tech roles and you will not find the right candidates here for this role.\n\n';
                }

                if (aData['message'].includes('updazz.com')) {
                    obj.statusDesc =
                        statusDesc +
                        "Please post this job on our other job portal - updazz.com. It's absolutely free and easy.";
                    obj.redirectUrl =
                        'https://www.updazz.com/recruiter/login.php?ref=ns' +
                        baseDomain;
                    obj.btnText = 'Go to updazz.com > ';
                }

                if (aData['message'].includes('biojoby.com')) {
                    obj.statusDesc =
                        statusDesc +
                        "Please post this job on biojoby.com - our job portal for medical, pharma & healthcare jobs It's absolutely free and easy. ";
                    obj.redirectUrl =
                        'https://www.biojoby.com/recruiter/login.php?ref=ns' +
                        baseDomain;
                    obj.btnText = 'Go to biojoby.com > ';
                }

                if (aData['message'].includes('engineeristic.com')) {
                    obj.statusDesc =
                        statusDesc +
                        "Please post this job on engineeristic.com - our job portal for manufacturing, operation and engineering jobs. It's absolutely free and easy. ";
                    obj.redirectUrl =
                        'https://www.engineeristic.com/recruiter/login.php?ref=ns' +
                        baseDomain;
                    obj.btnText = 'Go to engineeristic.com > ';
                }

                if (
                    aData['message'].includes('Please post all technical jobs')
                ) {
                    obj.statusDesc =
                        statusDesc +
                        "Please post this job on hirist.tech - our job portal for tech roles. It's absolutely free and easy. ";
                    obj.redirectUrl =
                        'https://recruit.hirist.tech/login?ref=ns' + baseDomain;
                    obj.btnText = 'Go to hirist.tech > ';
                }

                if (
                    aData['message'].includes(
                        'Please post this job on iimjobs.com',
                    )
                ) {
                    obj.redirectUrl =
                        'https://recruit.iimjobs.com/login?ref=ns' + baseDomain;
                    obj.btnText = 'Go to iimjobs.com > ';
                    obj.statusDesc =
                        statusDesc +
                        "Please post this job on iimjobs.com - our job portal for mid-senior/senior level jobs. It's absolutely free and easy. ";
                }
            }

            if (
                aData['message'].includes(
                    'All locations have been updated within the same job posting which is published.',
                )
            ) {
                obj.statusDesc =
                    'We noticed that you had posted the same job with different locations. We have updated all the locations within the same job posting and published it.';
            }

            if (
                aData['message'].includes(
                    'This job has already been posted on the portal',
                )
            ) {
                obj.statusDesc =
                    'Another job with the same JD has already been published on the portal.';
            }

            if (
                aData['message'].includes(
                    'Regret we cannot post this job as it is not relevant to our target audience',
                )
            ) {
                obj.statusDesc =
                    'Every job posted on ' +
                    baseDomain +
                    ' is reviewed on certain parameters and is published only if deemed fit. This is done to ensure a good quality experience for the candidates on ' +
                    baseDomainName;
            }

            if (
                aData['message'].includes(
                    'Unfortunately, the salary offered for this job is low.',
                )
            ) {
                obj.statusDesc =
                    'This job could not be published on ' +
                    baseDomainName +
                    'as it does not meet our minimum salary criteria.\n\n' +
                    baseDomainName +
                    'is job portal for mid-senior/senior level roles from premium institutes and we do not post jobs below a certain salary range to have the best experience for our candidates.';
            }

            if (
                aData['message'].includes(
                    'Unfortunately, the salary offered for this job is low vis-a-vis the years of experience required.',
                )
            ) {
                obj.statusDesc =
                    'This job could not be published on ' +
                    baseDomainName +
                    'as it does not meet our minimum salary criteria for the required experience.\n\n' +
                    baseDomainName +
                    'is job portal for mid-senior/senior level roles from premium institutes and we do not post jobs below a certain salary range to have the best experience for our candidates.';
            }

            break;
        case 'pending':
            obj['status'] = 'Under review';
            obj['statusMsg'] = false;
            obj['message'] =
                'We are reviewing this job. This usually takes up to 4 hours if posted between 9am-5pm on a working weekday.';
            obj['statusDesc'] =
                "The job is under review and will be published soon on the platform. Once it is published, we will notify you via email, post which you'll start receiving applications from relevant candidates.";
            break;
        case 'published':
            obj['status'] = 'Published';
            obj['statusMsg'] = false;
            obj['actions'] = true;
            if (aData['cnfi']) {
                obj['extraStatus'] = true;
                obj['extraStatusMsg'] = '(Confidential)';
            }
            break;
        case 'updated-published':
            obj['statusMsg'] = false;
            obj['status'] = 'Published';
            obj['actions'] = true;
            obj['extraStatus'] = true;
            if (aData['cnfi']) {
                obj['extraStatusMsg'] = '(Confidential)';
            }
            break;
        case 'unpublished':
            obj['status'] = 'Unpublished';
            if (aData['message']) {
                obj['message'] = aData['message'];
            }
            break;
        case 'updated-unpublished':
            obj['status'] = 'Unpublished';
            obj['extraStatus'] = true;
            if (aData['message']) {
                obj['message'] = aData['message'];
            }
            break;
        default:
            break;
    }
    return obj;
}

export function setCourseStatus(aData) {
    const baseDomain = process.env.REACT_APP_BASE_DOMAIN;
    var obj = {
        status: aData['status'],
        message: 'Nothing to show',
        statusMsg: true,
        actions: false,
        extraStatus: false,
        extraStatusMsg: 'But updated',
    };
    switch (aData['status']) {
        case 'Rejected':
            obj['status'] = 'Rejected';
            obj['statusMsg'] = false;
            break;
        case 'Pending':
            obj['status'] = 'Under review';
            obj['statusMsg'] = false;
            obj['message'] =
                'We are reviewing this course. This usually takes up to 4 hours if posted between 9am-5pm on a working weekday.';
            obj['statusDesc'] =
                "The course is under review and will be published soon on the platform. Once it is published, we will notify you via email, post which you'll start receiving applications from relevant candidates.";
            break;
        case 'Published':
            obj['status'] = 'Published';
            obj['statusMsg'] = false;
            break;
        case 'Unpublished':
            obj['status'] = 'Unpublished';
            obj['statusMsg'] = false
            break;
        default:
            break;
    }
    return obj;
}
export function getFacebookShareLink(url) {
    return (
        'https://www.facebook.com/sharer/sharer.php?u=' +
        encodeURIComponent(url)
    );
}

export function dynamicallyLoad(url, callback) {
    let script = document.createElement('script');
    script.type = 'text/javascript'; // create a script DOM node
    script.src = url; // set its src to the provided URL
    script.onreadystatechange = function () {
        if (this.readyState === 'complete') {
            callback();
        }
    };
    script.onload = function () {
        callback();
    };
    document.head.appendChild(script);
}

export const getRandom = (max = 1, min = 0) => {
    return Math.floor(Math.random() * (max - min) + min);
};

export const setStorage = (key, obj) => {
    try {
        localStorage.setItem(key, JSON.stringify(obj));
    } catch (error) {
        // console.log(error);
    }
};

export const removeFromStorage = key => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.log(error);
    }
};

export const getFromStorage = key => {
    try {
        const value = localStorage.getItem(key);
        if (value == null) {
            return 0;
        }
        return value;
    } catch (error) {
        return null;
    }
};

export const getDateFormat = (date) => {
    return moment(date).format('YYYY') === moment().format('YYYY')
        ? moment(date).format('MMM DD')
        : moment(date).format('ll');
}