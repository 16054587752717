let searchUrl = process.env.REACT_APP_SEARCH_URL;
let calculusUrl = process.env.REACT_APP_CALCULUS_URL;

export const productItems = {
    title: 'Products',
    titleClassToAddBody: 'products_nav',
    titleClassToAddUl: 'products_nav_ul',
    titleClassToAddMenu: 'products_nav_menu',
    link: '',
    // onClick: handler => handler.bind(null, 'myjobs'),
    isReact: false,
    isHidden: false,
    children: [
        {
            hash: 4,
            title: 'Premium Posting',
            subtitle: 'High visibility for Best Talent',
            link: "/offerings?productHash=4&ref=premium_posting",
            onClick: handler => handler.bind(null, 'calculus'),
        },
        {   
            hash: 1,
            title: 'Search Resume',
            subtitle: 'Discover Preferred candidate from Best Talent',
            link: "/offerings?productHash=1&ref=search_resume",
            onClick: handler => handler.bind(null, 'search'),
        },
        {
            hash: 2,
            title: 'Calculus - Salary Benchmarking Tool',
            subtitle: 'Salary Benchmarking Tool',
            link: "/offerings?productHash=2&ref=calculus",
            onClick: handler => handler.bind(null, 'calculus'),
        },
        {
            hash: 5,
            title: 'Employer Branding',
            subtitle: 'Build brand awareness for Top Talent ',
            link: "/offerings?productHash=5&ref=employer_branding",
            onClick: handler => handler.bind(null, 'search'),
        },
        {
            hash: 3, 
            title: 'Diversity Hiring',
            subtitle: 'Inclusive hiring',
            link: "/offerings?productHash=3&ref=calculus",
            onClick: handler => handler.bind(null, 'calculus'),
        }
    ],
};
