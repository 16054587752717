export const domains = [
    {
        domain: 'iimjobs',
        heading: 'iimjobs.com',
        subheading: 'For MBA Jobs',
        newSubHeading: '(MBA Jobs)',
        alias: 'MBA Jobs',
        link: 'https://www.iimjobs.com',
    },
    {
        domain: 'hirist',
        heading: 'hirist.tech',
        subheading: 'For Tech Jobs',
        newSubHeading: '(Tech Jobs)',
        alias: 'IT Jobs',
        link: 'https://www.hirist.tech',
    },
    {
        domain: 'updazz',
        heading: 'updazz.com',
        subheading: 'For Sales & Marketing Jobs',
        newSubHeading: '(Sales Jobs)',
        alias: 'Sales Jobs',
        link: 'https://www.updazz.com',
    },
    {
        domain: 'engineeristic',
        heading: 'engineeristic.com',
        subheading: ' For Manufacturing, Operation and Engineering Jobs',
        newSubHeading: '(Engineering Jobs)',
        alias: 'Engineering Jobs',
        link: 'https://www.engineeristic.com',
    },
    {
        domain: 'biojoby',
        heading: 'biojoby.com',
        subheading: 'For Medical, Pharma & Healthcare Jobs',
        newSubHeading: '(Medical Jobs)',
        alias: 'Medical Jobs',
        link: 'https://www.biojoby.com',
    },
];

export const otherDomains = domains.filter(item => {
    return item.domain !== process.env.REACT_APP_BASE_DOMAIN;
});
