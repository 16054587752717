const location = [
    {
        label: "Select Location",
        value: 0
    },
    {
        label: "Ahmedabad",
        value: 1
    },
    {
        label: "Bengaluru / Bangalore",
        value: 2
    },
    {
        label: "Chandigarh",
        value: 41
    },
    {
        label: "Chennai",
        value: 3
    },
    {
        label: "Delhi",
        value: 221,
        "isNcr": "Ncr"
    },
    {
        label: "Delhi/NCR",
        value: 4
    },
    {
        label: "Gurgaon",
        value: 5,
        "isNcr": "Ncr"
    },
    {
        label: "Hyderabad",
        value: 6
    },
    {
        label: "Kolkata",
        value: 7
    },
    {
        label: "Mumbai",
        value: 8
    },
    {
        label: "Noida",
        value: 9,
        "isNcr": "Ncr"
    },
    {
        label: "Pune",
        value: 10
    },
    {
        label: "Guntur",
        value: 62
    },
    {
        label: "Kakinada",
        value: 82
    },
    {
        label: "Kurnool",
        value: 98
    },
    {
        label: "Nellore",
        value: 113
    },
    {
        label: "Nizamabad",
        value: 114
    },
    {
        label: "Rajamundry",
        value: 129
    },
    {
        label: "Tirupati",
        value: 150
    },
    {
        label: "Vijayawada",
        value: 160
    },
    {
        label: "Visakhapatnam",
        value: 161
    },
    {
        label: "Warangal",
        value: 162
    },
    {
        label: "Anantapur",
        value: 222
    },
    {
        label: "Guntakal",
        value: 223
    },
    {
        label: "Rajahmundry",
        value: 225
    },
    {
        label: "Secunderabad",
        value: 226
    },
    {
        label: "Andhra Pradesh - Other",
        value: 227
    },
    {
        label: "Itanagar",
        value: 72
    },
    {
        label: "Arunachal Pradesh - Other",
        value: 228
    },
    {
        label: "Dispur",
        value: 51
    },
    {
        label: "Guwahati",
        value: 63
    },
    {
        label: "Silchar",
        value: 140
    },
    {
        label: "Assam - Other",
        value: 229
    },
    {
        label: "Bhilaigarh",
        value: 34
    },
    {
        label: "Patna",
        value: 122
    },
    {
        label: "Bihar - Other",
        value: 230
    },
    {
        label: "Bhagalpur",
        value: 26
    },
    {
        label: "Bilaspur",
        value: 39
    },
    {
        label: "Raipur",
        value: 128
    },
    {
        label: "Bhillai",
        value: 231
    },
    {
        label: "Chhattisgarh - Other",
        value: 232
    },
    {
        label: "Goa",
        value: 59
    },
    {
        label: "Panaji",
        value: 233
    },
    {
        label: "Panjim",
        value: 234
    },
    {
        label: "Vasco Da Gama",
        value: 235
    },
    {
        label: "Goa - Other",
        value: 236
    },
    {
        label: "Anand",
        value: 21
    },
    {
        label: "Ankleshwar",
        value: 22
    },
    {
        label: "Baroda",
        value: 28
    },
    {
        label: "Bharuch",
        value: 31
    },
    {
        label: "Bhavnagar",
        value: 33
    },
    {
        label: "Bhuj",
        value: 37
    },
    {
        label: "Gandhinagar",
        value: 56
    },
    {
        label: "Jamnagar",
        value: 79
    },
    {
        label: "Kandla",
        value: 83
    },
    {
        label: "Porbandar",
        value: 124
    },
    {
        label: "Rajkot",
        value: 130
    },
    {
        label: "Surat",
        value: 145
    },
    {
        label: "Vadodara",
        value: 155
    },
    {
        label: "Valsad",
        value: 156
    },
    {
        label: "Vapi",
        value: 157
    },
    {
        label: "Gir",
        value: 238
    },
    {
        label: "Gujarat - Other",
        value: 239
    },
    {
        label: "Ambala",
        value: 19
    },
    {
        label: "Faridabad",
        value: 55,
        "isNcr": "Ncr"
    },
    {
        label: "Hisar",
        value: 66
    },
    {
        label: "Karnal",
        value: 86
    },
    {
        label: "Kurukshetra",
        value: 99
    },
    {
        label: "Panipat",
        value: 117
    },
    {
        label: "Rohtak",
        value: 132
    },
    {
        label: "Sonipat / Sonepat",
        value: 143
    },
    {
        label: "Yamunanagar",
        value: 164
    },
    {
        label: "Haryana - Other",
        value: 242
    },
    {
        label: "Baddi",
        value: 25
    },
    {
        label: "Dalhousie",
        value: 45
    },
    {
        label: "Dharamsala",
        value: 49
    },
    {
        label: "Kulu/Manali",
        value: 97
    },
    {
        label: "Shimla",
        value: 137
    },
    {
        label: "Himachal Pradesh - Other",
        value: 244
    },
    {
        label: "Jammu",
        value: 78
    },
    {
        label: "Srinagar",
        value: 144
    },
    {
        label: "Jammu and Kashmir - Other",
        value: 245
    },
    {
        label: "Bokaro",
        value: 40
    },
    {
        label: "Dhanbad",
        value: 48
    },
    {
        label: "Jamshedpur",
        value: 80
    },
    {
        label: "Ranchi",
        value: 131
    },
    {
        label: "Jharkhand - Other",
        value: 246
    },
    {
        label: "Belgaum",
        value: 29
    },
    {
        label: "Bellary",
        value: 30
    },
    {
        label: "Bidar",
        value: 38
    },
    {
        label: "Dharwad",
        value: 50
    },
    {
        label: "Gulbarga",
        value: 61
    },
    {
        label: "Hubli",
        value: 68
    },
    {
        label: "Kolar",
        value: 91
    },
    {
        label: "Mangalore",
        value: 104
    },
    {
        label: "Mysore",
        value: 109
    },
    {
        label: "Shimoga",
        value: 138
    },
    {
        label: "Karnataka - Other",
        value: 248
    },
    {
        label: "Alappuzha / Alleppey",
        value: 16
    },
    {
        label: "Cochin / Kochi / Ernakulam",
        value: 42
    },
    {
        label: "Idukki",
        value: 69
    },
    {
        label: "Kannur",
        value: 84
    },
    {
        label: "Kasargod",
        value: 87
    },
    {
        label: "Kollam",
        value: 93
    },
    {
        label: "Kottayam",
        value: 95
    },
    {
        label: "Kozhikode / Calicut",
        value: 96
    },
    {
        label: "Malappuram",
        value: 103
    },
    {
        label: "Pallakad",
        value: 116
    },
    {
        label: "Pathanamthitta",
        value: 119
    },
    {
        label: "Thrissur / Trissur",
        value: 147
    },
    {
        label: "Thiruvananthapuram / Trivandrum",
        value: 148
    },
    {
        label: "Wayanad",
        value: 163
    },
    {
        label: "Palakkad",
        value: 249
    },
    {
        label: "Palghat",
        value: 250
    },
    {
        label: "Kerala - Other",
        value: 251
    },
    {
        label: "Bhopal",
        value: 35
    },
    {
        label: "Gwalior",
        value: 64
    },
    {
        label: "Indore",
        value: 71
    },
    {
        label: "Jabalpur",
        value: 73
    },
    {
        label: "Ujjain",
        value: 154
    },
    {
        label: "Madhya Pradesh - Other",
        value: 252
    },
    {
        label: "Ahmednagar",
        value: 13
    },
    {
        label: "Aurangabad",
        value: 24
    },
    {
        label: "Jalgaon",
        value: 77
    },
    {
        label: "Kolhapur",
        value: 92
    },
    {
        label: "Nagpur",
        value: 111
    },
    {
        label: "Nasik",
        value: 112
    },
    {
        label: "Sholapur",
        value: 139
    },
    {
        label: "Mumbai Suburbs",
        value: 254
    },
    {
        label: "Navi Mumbai",
        value: 255
    },
    {
        label: "Solapur",
        value: 257
    },
    {
        label: "Maharashtra - Other",
        value: 258
    },
    {
        label: "Imphal",
        value: 70
    },
    {
        label: "Manipur - Other",
        value: 259
    },
    {
        label: "Shillong",
        value: 136
    },
    {
        label: "Meghalaya - Other",
        value: 260
    },
    {
        label: "Aizawal",
        value: 14
    },
    {
        label: "Mizoram - Other",
        value: 261
    },
    {
        label: "Kohima",
        value: 90
    },
    {
        label: "Dimapur",
        value: 262
    },
    {
        label: "Nagaland - Other",
        value: 263
    },
    {
        label: "Bhubaneshwar",
        value: 36
    },
    {
        label: "Cuttack",
        value: 44
    },
    {
        label: "Paradeep",
        value: 118
    },
    {
        label: "Puri",
        value: 126
    },
    {
        label: "Rourkela",
        value: 134
    },
    {
        label: "Orissa - Other",
        value: 264
    },
    {
        label: "Amritsar",
        value: 20
    },
    {
        label: "Bhatinda",
        value: 32
    },
    {
        label: "Jalandhar",
        value: 76
    },
    {
        label: "Ludhiana",
        value: 101
    },
    {
        label: "Mohali",
        value: 107
    },
    {
        label: "Pathankot",
        value: 120
    },
    {
        label: "Patiala",
        value: 121
    },
    {
        label: "Bathinda",
        value: 265
    },
    {
        label: "Punjab - Other",
        value: 267
    },
    {
        label: "Ajmer",
        value: 15
    },
    {
        label: "Jaipur",
        value: 74
    },
    {
        label: "Jaisalmer",
        value: 75
    },
    {
        label: "Jodhpur",
        value: 81
    },
    {
        label: "Kota",
        value: 94
    },
    {
        label: "Udaipur",
        value: 153
    },
    {
        label: "Rajasthan - Other",
        value: 268
    },
    {
        label: "Gangtok",
        value: 57
    },
    {
        label: "Sikkim - Other",
        value: 269
    },
    {
        label: "Coimbatore",
        value: 43
    },
    {
        label: "Erode",
        value: 53
    },
    {
        label: "Hosur",
        value: 67
    },
    {
        label: "Madurai",
        value: 102
    },
    {
        label: "Nagercoil",
        value: 110
    },
    {
        label: "Ooty",
        value: 115
    },
    {
        label: "Salem",
        value: 135
    },
    {
        label: "Thanjavur",
        value: 146
    },
    {
        label: "Tirunalveli",
        value: 149
    },
    {
        label: "Trichy",
        value: 151
    },
    {
        label: "Tuticorin",
        value: 152
    },
    {
        label: "Vellore",
        value: 159
    },
    {
        label: "Cuddalore",
        value: 271
    },
    {
        label: "Tamil Nadu - Other",
        value: 272
    },
    {
        label: "Agartala",
        value: 11
    },
    {
        label: "Tripura - Other",
        value: 273
    },
    {
        label: "Daman",
        value: 46
    },
    {
        label: "Kavaratti",
        value: 88
    },
    {
        label: "Pondicherry",
        value: 123
    },
    {
        label: "Port Blair",
        value: 125
    },
    {
        label: "Silvassa",
        value: 142
    },
    {
        label: "Dadra and Nagar Haveli",
        value: 275
    },
    {
        label: "Agra",
        value: 12
    },
    {
        label: "Aligarh",
        value: 17
    },
    {
        label: "Allahabad",
        value: 18
    },
    {
        label: "Bareilly",
        value: 27
    },
    {
        label: "Faizabad",
        value: 54
    },
    {
        label: "Ghaziabad",
        value: 58,
        "isNcr": "Ncr"
    },
    {
        label: "Gorakhpur",
        value: 60
    },
    {
        label: "Kanpur",
        value: 85
    },
    {
        label: "Lucknow",
        value: 100
    },
    {
        label: "Mathura",
        value: 105
    },
    {
        label: "Meerut",
        value: 106
    },
    {
        label: "Moradabad",
        value: 108
    },
    {
        label: "Varanasi",
        value: 158
    },
    {
        label: "Uttar Pradesh - Other",
        value: 278
    },
    {
        label: "Dehradun",
        value: 47
    },
    {
        label: "Roorkee",
        value: 133
    },
    {
        label: "Uttaranchal - Other",
        value: 279
    },
    {
        label: "Asansol",
        value: 23
    },
    {
        label: "Durgapur",
        value: 52
    },
    {
        label: "Haldia",
        value: 65
    },
    {
        label: "Kharagpur",
        value: 89
    },
    {
        label: "Siliguri",
        value: 141
    },
    {
        label: "West Bengal - Other",
        value: 281
    },
    {
        label: "Other India",
        value: 165
    },
    {
        label: "Quilon",
        value: 127
    },
    {
        label: "Australia",
        value: 166
    },
    {
        label: "Bahrain",
        value: 167
    },
    {
        label: "Bangladesh",
        value: 168
    },
    {
        label: "Belgium",
        value: 169
    },
    {
        label: "Canada",
        value: 170
    },
    {
        label: "China",
        value: 171
    },
    {
        label: "Doha",
        value: 214
    },
    {
        label: "Dubai",
        value: 215
    },
    {
        label: "Egypt",
        value: 172
    },
    {
        label: "France",
        value: 173
    },
    {
        label: "Germany",
        value: 174
    },
    {
        label: "Gulf",
        value: 175
    },
    {
        label: "Hongkong",
        value: 176
    },
    {
        label: "Indonesia",
        value: 177
    },
    {
        label: "INDIA",
        value: 216
    },
    {
        label: "Ireland",
        value: 178
    },
    {
        label: "Italy",
        value: 217
    },
    {
        label: "Japan",
        value: 179
    },
    {
        label: "Jordan",
        value: 180
    },
    {
        label: "Kenya",
        value: 218
    },
    {
        label: "Kuwait",
        value: 181
    },
    {
        label: "Lebanon",
        value: 182
    },
    {
        label: "Libya",
        value: 219
    },
    {
        label: "Macau",
        value: 183
    },
    {
        label: "Malaysia",
        value: 184
    },
    {
        label: "Maldives",
        value: 185
    },
    {
        label: "Mauritius",
        value: 186
    },
    {
        label: "Mexico",
        value: 187
    },
    {
        label: "Nepal",
        value: 188
    },
    {
        label: "Netherlands",
        value: 189
    },
    {
        label: "New Zealand",
        value: 190
    },
    {
        label: "Norway",
        value: 220
    },
    {
        label: "Oman",
        value: 191
    },
    {
        label: "Pakistan",
        value: 192
    },
    {
        label: "Philippines",
        value: 193
    },
    {
        label: "Qatar",
        value: 194
    },
    {
        label: "Russia",
        value: 195
    },
    {
        label: "Saudi Arabia",
        value: 196
    },
    {
        label: "Singapore",
        value: 197
    },
    {
        label: "South Africa",
        value: 198
    },
    {
        label: "South Korea",
        value: 199
    },
    {
        label: "Spain",
        value: 200
    },
    {
        label: "SriLanka",
        value: 201
    },
    {
        label: "Sweden",
        value: 202
    },
    {
        label: "Switzerland",
        value: 203
    },
    {
        label: "Taiwan",
        value: 204
    },
    {
        label: "Thailand",
        value: 205
    },
    {
        label: "United Kingdom",
        value: 206
    },
    {
        label: "United Arab Emirates",
        value: 207
    },
    {
        label: "United States",
        value: 208
    },
    {
        label: "Vietnam",
        value: 209
    },
    {
        label: "Yemen",
        value: 210
    },
    {
        label: "Zimbabwe",
        value: 211
    },
    {
        label: "Luxembourg",
        value: 282
    },
    {
        label: "Other International",
        value: 212
    },
    {
        label: "Anywhere",
        value: 213
    }]
const  tempLocation = location.slice()
export const organizationLocation  = tempLocation.sort(function (a, b) {

        // Always keep Select option at the top
        if (a.value === 0) {
            return -1;
        }
        if (b.value === 0) {
            return 1;
        }
        return a['label'].localeCompare(b.label)
    });

export const unsortedOrgLocation = location;
