import React from 'react';
import { Button } from '../../../../components/Button';
import './styles.scss';
import { endpoints } from '../../../../../constants/endpoints';

let HeaderDropDown;
export default HeaderDropDown = props => {
    const joinRoom = () => {
        window.open(
            endpoints.interviewMeet +
                '?inviteId=' +
                props.candidate.id +
                '&calleeId=' +
                props.candidate.userID +
                '&ref=header',
        );
    };
    return (
        <li className="interviewCandidateWrapper">
            <span className="interview-imgCandidateWrapper">
                <img
                    className="interview-imgCandidate"
                    src={
                        props.candidate.completeImgPath ||
                        '/static/images/noimage.png'
                    }
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // Added to prevent lopping
                        currentTarget.src = '/static/images/noimage.png';
                    }}
                    alt="user_img"
                />
            </span>
            <span className="interview-candidateDeatilsWrapper">
                <div className="interview-candidate-name">
                    {props.candidate.name}
                </div>
                <div className="interview-candidateSlot-timing">
                    {props.candidate.slot.showTime}
                </div>
            </span>
            {props.candidate.slot.type == 3 ? (
                <Button
                    type={'interview-invite-calButtonType'}
                    classes={['interview-invite-calButton']}
                    onClick={joinRoom}
                >
                    Join Room
                </Button>
            ) : null}
        </li>
    );
};
