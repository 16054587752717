import React, { useState, useEffect } from 'react';
import { endpoints } from '../../../../../../../constants/endpoints';
import './styles.scss';

export const CreditLabels = props => {
    const profile = props.profile;
    const classAddOn = props.classAddOn;
    const [
        premiumCreditsValidityHtml,
        setPremiumCreditsValidityHtml,
    ] = useState(null);
    const [creditsExpiryTextHtml, setCreditsExpiryTextHtml] = useState(null);
    useEffect(() => {
        if (parseInt(profile.availableCredits)) {
            var premiumCredInfoArray = profile.premiumCredInfoArray;

            var objDateHeader = new Date();
            objDateHeader.setDate(1);
            objDateHeader.setMonth(
                parseInt(premiumCredInfoArray.validityMonth),
            );

            var locale = 'en-us';
            var validityMonth;

            validityMonth = objDateHeader.toLocaleString(locale, {
                month: props.monthLength,
            });

            let htmlPremiumCreditsValidTill = (
                <span className={'premiumCreditsValidTillValue'}>
                    {' ' + validityMonth + ' ' + premiumCredInfoArray.validity}{' '}
                </span>
            );
            setPremiumCreditsValidityHtml(htmlPremiumCreditsValidTill);
            setCreditsExpiryTextHtml(props.expiryText);
            if (premiumCredInfoArray.activePlansCount > 1) {
                if (!props.addHyperLink) {
                    setPremiumCreditsValidityHtml(
                        <a className="viewExpiryClickable textColorGreen">
                            {' '}
                            View Expiry Date
                        </a>,
                    );
                } else {
                    setPremiumCreditsValidityHtml(
                        <a
                            href={
                                endpoints.recruiterProfile + '?selectedMenu=6'
                            }
                            className="textColorGreen"
                        >
                            {' '}
                            View Expiry Date
                        </a>,
                    );
                }
                setCreditsExpiryTextHtml(
                    premiumCredInfoArray.expiringCreditsNum +
                        ' credits expiring in ' +
                        premiumCredInfoArray.expiryDuration +
                        (props.addExclamation ? '!' : ''),
                );
            }
        }
    }, []);

    return (
        <>
            {parseInt(profile.availableCredits) ? (
                <div className={'premiumCreditsValidity ' + classAddOn}>
                    <span className={'premiumCreditsValidTill'}>
                        <img
                            src="/static/images/calendar_(1)Grey.svg"
                            className={'calendarImgPremiumCredits'}
                        />
                        Valid Till: {premiumCreditsValidityHtml}
                    </span>
                </div>
            ) : null}

            {profile.premiumCredInfoArray.expiryDuration != '' &&
            parseInt(profile.premiumCredInfoArray.deactivated) != 1 ? (
                <div className={'premiumCreditsExpiry ' + classAddOn}>
                    <span className={'creditsExpiryText'}>
                        <img
                            src="/static/images/termGrey.svg"
                            className={'termImgPremiumCredits'}
                        />
                        {creditsExpiryTextHtml}
                    </span>
                </div>
            ) : null}
            {parseInt(profile.premiumCredInfoArray.deactivated) ? (
                <div className={'premiumCreditsDeact ' + classAddOn}>
                    <span className={'premiumCreditsDeactText'}>
                        <img
                            src="/static/images/alert.svg"
                            className={'alertImgPremiumCredits'}
                        />
                        Your Plan has been deactivated
                        <img
                            src="/static/images/doubts-button.svg"
                            className={'doubtImgPremiumCredits'}
                        />
                    </span>
                    <div className={'comments-prompt'} id="doubtImgHover">
                        <div className={'description'}>
                            For more information, please contact your account
                            manager or write to us at info@
                            {process.env.REACT_APP_BASE_DOMAIN_NAME}
                            <div className={'triangle-down-container'}>
                                <div className={'triangle-down'}></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
