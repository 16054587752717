import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

export const FieldError = props => {
    return (
        <div
            className={`r-error ${(props.classes || []).join(' ')}`}
            {...props.options}
        >
            {props.children}
        </div>
    );
};

FieldError.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.object,
};
