import React from 'react';
import './styles.scss';
import HeaderDropDown from './components/HeaderDropDown';
import { fetchInterviews } from '../../../services/fetchInterviews';
import moment from 'moment';
import { Overlay } from '../../components/Overlay';
import { endpoints } from '../../../constants/endpoints';

export default class InterviewCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerDropDownClass: '',
            interviewsData: [],
            totalInterviews: 0,
        };
        this.handleEnterClass = this.handleEnterClass.bind(this);
        this.handleLeaveClass = this.handleLeaveClass.bind(this);
        this.outSideClick = this.outSideClick.bind(this);
    }
    async componentDidMount() {
        const recruiterId = this.props.recruiterId;
        const lastLogin = localStorage.getItem('lastLogin');
        let today = moment().format('YYYY-MM-DD');
        today = moment(today);
        if (today.diff(lastLogin, 'days') !== 0) {
            localStorage.setItem('lastLogin', moment().format('YYYY-MM-DD'));
            this.setState({
                headerDropDownClass: ' active',
            });
        }

        const typeMap = {
            1: 'Face to Face',
            2: 'Telephonic',
            3: 'Video',
        };

        const params = {
            fromDate: moment().format('YYYY-MM-DD'),
            fromTime: moment().subtract(30, 'minutes').format('HHmm'),
            toDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            pageNumber: 1,
            status: 2,
        };
        let interviewsData = await fetchInterviews(recruiterId, { params });
        let totalInterviews = interviewsData.data.data.length;
        interviewsData = interviewsData.data.data.slice(0, 3);
        interviewsData.forEach(element => {
            var temp =
                element.slot.time.substr(0, 2) +
                ':' +
                element.slot.time.substr(2, 2);
            var temp1 = moment(temp, 'hh:mm')
                .add(30, 'minutes')
                .format('hh:mm A');
            temp = moment(temp, 'hh:mm').format('hh:mm');
            var showTime = temp + ' - ' + temp1;
            element.slot.showTime =
                showTime + ' - ' + typeMap[element.slot.type];
        });
        this.setState({
            interviewsData: interviewsData,
            totalInterviews: totalInterviews,
        });
    }
    handleEnterClass() {
        this.setState({
            headerDropDownClass: ' active',
        });
    }
    handleLeaveClass() {
        this.setState({ headerDropDownClass: '' });
    }
    outSideClick() {
        this.setState({ headerDropDownClass: '' });
    }
    render() {
        return this.state.interviewsData.length === 0 ? null : (
            <Overlay
                outsideClick={this.outSideClick}
                type="interview-outSideClickClass"
            >
                <>
                    <div className="interview-imageBadgeWrapper">
                        <img
                            src="/static/images/smallcalendarWhite.svg"
                            className="interview-CalendarLink header-links"
                            alt="img"
                            onMouseEnter={this.handleEnterClass}
                            onMouseLeave={this.handleLeaveClass}
                        />
                        <span
                            className="interview-badge"
                            onMouseEnter={this.handleEnterClass}
                            onMouseLeave={this.handleLeaveClass}
                        >
                            {this.state.totalInterviews}
                        </span>
                    </div>
                    <div
                        className={
                            'interviewCal-profile-menu' +
                            this.state.headerDropDownClass
                        }
                        onMouseEnter={this.handleEnterClass}
                        onMouseLeave={this.handleLeaveClass}
                    >
                        <div className="interviewCal-profile-body">
                            <ul className="interviewCal-profile-item-listing interviewsCalendarData">
                                Today's Interview
                                {this.state.interviewsData.map(
                                    (candidate, i) => (
                                        <HeaderDropDown
                                            candidate={candidate}
                                            key={i}
                                        />
                                    ),
                                )}
                                <div className="interview-viewAllCandidates">
                                    <a href={endpoints.interviews}>
                                        View All
                                        <i className="icon-right_arrow"></i>
                                    </a>
                                </div>
                            </ul>
                        </div>
                    </div>
                </>
            </Overlay>
        );
    }
}
