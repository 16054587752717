import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../Button';
import { Modal } from '../../../Modal';
import { postLeads } from '../../../../../services/postLeads';
import { updateProfile } from '../../../../../services/updateProfile';
import { sendEvent } from '../../../../../utils/sendEvent';
import { organizationLocation } from '../../../../../constants/organizationLocation';
import { noOfEmployees } from '../../../../../constants/noOfEmployees';
import { Dropdown } from '../../../Dropdown';
import { FieldError } from '../../../FieldError';
import { selectProfile } from '../../../../selector';
import { useSelector } from 'react-redux';
import * as toast from '../../../../containers/Toast';


import './styles.scss';

const searchResumeConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/searchResumeConfig.json`);

export const SearchModal = props => {
    // const salesMail = `Sales@${process.env.REACT_APP_BASE_DOMAIN_NAME}`;

    const baseDomainName = process.env.REACT_APP_BASE_DOMAIN_NAME;
    let storage = JSON.parse(localStorage.getItem('sessionStatus')) || {};
    const { recruiterId } = props;
    const data = {
        recruiterId: recruiterId,
        additionalInfo: {
            enquiry_type: 'New',
        },
    };

    const salesMail = `sales@${baseDomainName}`;

    const profile = useSelector(selectProfile);
    const [loading, setLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(true);
    const [validations, setValidations] = useState({});
    const [orgLocation, setOrgLocation] = useState(0);
    const [companySize, setCompanySize] = useState(0);
    const [apiResFail, setApiResFail] = useState(false);

    const newData = {
        ...data,
        productType: '1',
        activity: '1',
        source: '1',
    };

    useEffect(() => {
        if (!storage['searchClick']) {
            try{
                postLeads(recruiterId, newData);
                storage = { ...storage, searchClick: true };
                localStorage.setItem('sessionStatus', JSON.stringify(storage));
            }
            catch(e) {
                console.log(e);
            }
        }
    }, [recruiterId]);

    const demoLabel = `recid=${recruiterId},origin=Navbar`;

    async function requestDemo() {

        const { pass, vals } = validate();
        if (pass) {
            setLoading(true);
            setShowMsg(false);

            try {
                if (orgLocation)
                    await updateProfile(recruiterId, { location: orgLocation });
                await sendEnquiry();
            } catch (err) {
                console.log(err);
                setLoading(false);
                setApiResFail(true);
            }
        } else {
            setValidations(vals);
            return;
        }
    }

    async function sendEnquiry() {
        setLoading(true);
        try {
            const newData = {
                ...data,
                productType: '1',
                activity: '2',
                source: '1',
                companyType: profile.recruiterType,
                companySize
            };
            sendEvent('clickSearchResumeDemo', demoLabel);
            await postLeads(recruiterId, newData);
            storage = { ...storage, searchEnquiry: true };
            localStorage.setItem('sessionStatus', JSON.stringify(storage));
            setLoading(false);
        } catch (err) {
            if(err.response.data && err.response.data.code == 4040){
                toast.warning("Please try after sometime");
            }
            console.log(err);
            setLoading(false);
        }
    }

    function validate() {
        const vals = {
            orgLocationReq:
                (!orgLocation || parseInt(orgLocation) <= 0) &&
                    (!profile.location || parseInt(profile.location) <= 0)
                    ? 'Please select Organization Location'
                    : null,
            companySizeReq:
                (!companySize) && (!profile.companySize || parseInt(profile.companySize) <= 0)
                    ? 'Please select company size'
                    : null,
        };

        let pass = true;
        for (let key of Object.keys(vals)) {
            if (vals[key]) {
                pass = false;
                break;
            }
        }
        return {
            pass,
            vals,
        };
    }

    function onLocationChange(val) {
        setOrgLocation(val);
    }

    function onCompanySizeChange(val) {
        setCompanySize(val);
    }

    console.log(searchResumeConfig.heading);
    return (
        <Modal
            classes={["search-modal"]}
            onClose={props.onClose}
            
        >      
        <div className="head">Search Resume</div>
            {!storage['searchEnquiry'] ? (
                <div className="subhead">Find from Best Talent Pool</div>
            ) : null}
            <div className="parent_search">
                <div className="modal_left_search">
                    {!storage['searchEnquiry'] ? (
                        <div className="modal-text">
                            <div className="subHeading">
                                Source the Best Management Professionals quickly with our Premium Search Tool
                            </div>
                            <div className="section">
                                <table>
                                    <tbody>
                                        <tr className="info_text calculusRowHeight">
                                            <td className="indexNum">
                                                <img
                                                    src="/static/images/modal_tick-green.svg"
                                                    className="calculus_tick"
                                                    alt="*"
                                                />
                                            </td>
                                            <td className="info_calculus_points">
                                            4 out 5 candidates from IIMs are registered with us
                                            </td>
                                        </tr>
                                        <tr className="info_text calculusRowHeight">
                                            <td className="indexNum">
                                                <img
                                                    src="/static/images/modal_tick-green.svg"
                                                    className="calculus_tick"
                                                    alt="*"
                                                />
                                            </td>
                                            <td className="info_calculus_points ">
                                            Search using smart search filters
                                            </td>
                                        </tr>
                                        <tr className="info_text calculusRowHeight">
                                            <td className="indexNum">
                                                <img
                                                    src="/static/images/modal_tick-green.svg"
                                                    className="calculus_tick"
                                                    alt="*"
                                                />
                                            </td>
                                            <td className="info_calculus_points">
                                            Instantly connect with your preferred candidate
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : null}
                    <div className="line_container"></div>
                    {!storage['searchEnquiry'] && showMsg ? <>
                    {console.log((!profile.companySize))}
                        {((!profile.companySize) || (!profile.location || parseInt(profile.location) <= 0)) &&
                            <div className="subHeading subheading_share_details">
                                Share below details:
                            </div>
                        }
                            <div className="calculus_inputs_wrapper">
                                <div className="inputs_wrapper">
                                {
                                    (!profile.companySize) && (
                                        <div className="search-location input_1">
                                            <Dropdown
                                                type="text"
                                                onChange={onCompanySizeChange}
                                                label="Company Size*"
                                                classes={[
                                                    'companySize'
                                                ]}
                                                name="companySize"
                                                options={noOfEmployees}
                                                blackCaret
                                                defaultValue={companySize}
                                                errorMsg={validations.companySizeReq}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    (!profile.location || parseInt(profile.location) <= 0) && (
                                        <div className="search-location input_2">
                                            <Dropdown
                                                type="text"
                                                onChange={onLocationChange}
                                                label="State*"
                                                classes={[
                                                    'orgLocation',
                                                    `${apiResFail && 'error-border'}`,
                                                ]}
                                                name="orgLocation"
                                                options={organizationLocation}
                                                blackCaret
                                                defaultValue={orgLocation}
                                                errorMsg={validations.orgLocationReq}
                                            />
                                        </div>
                                    )
                                }
                            </div>  
                               
                            </div>
                    </> : null}
                    <div className="section">
                        {!storage['searchEnquiry'] ? (
                            <Button
                                isLoading={loading}
                                onClick={requestDemo}
                                classes={['green','lead-gen-button']}
                            >
                                Request a Callback
                            </Button>
                        ) : null}
                        {storage['searchEnquiry'] ? (
                            <p className="hidden" id="success_message">
                                Thank you for your interest in our Search Resume
                                product. Our team will be in touch with you shortly!
                            </p>
                        ) : null}
                        <br />
                        <br />
                    </div>
                </div>
                <div className="modal_right_search">
                        {/* <div> */}
                            <div className="modal_right_content_calculus">
                                <div className="calculus_videoArrowImgContainer">
                                    <img
                                        src="/static/images/search_resume_modal_image.svg"
                                        className="search_modal_right_image"
                                        alt=""
                                    />
                                    {/* <iframe
                                        className="calculus_videoIframe"
                                        src="https://www.youtube.com/embed/0QzRC-1GonM"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        data-testid="iframeCalculus"
                                ></iframe> */}
                                </div>
                                
                                <div className="line_container"></div>
                                <div className="info_text info_calculus_contactHeading">
                                    Connect with us at:
                                </div>
                                <div className="info_text info_calculus_contactInfo info_calculus_mail">
                                    <div class="content_icon">
                                        <i class="icon-email_envelope"></i>
                                    </div>
                                    <a href={`mailto:${salesMail}`} className='sales_mail'>{salesMail}</a>
                                </div>
                                <div className="info_text info_calculus_contactInfo">
                                    <div class="content_icon">
                                        <i class="icon-telephone"></i>
                                    </div>
                                    <span className="toll_free_text">Toll Free No.: 1800-103-7344</span>
                                </div>
                            </div>
                        {/* </div> */}
                </div>
            </div>
        </Modal>
    );
};

SearchModal.propTypes = {
    onClose: PropTypes.func,
};
