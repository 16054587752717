import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { checkEmail } from '../../../../../utils/checkEmail';

import SecurityModal from '../SecurityModal';

import {
    selectForgotPasswordError,
    selectForgotPasswordLoading,
    selectLinkSent,
    selectCaptchaRequired,
    selectCaptchaToken
} from '../../selectors';

import './styles.scss';

export const errorResponses = {
    missingEmail: 'Please enter the email address',
    invalidEmail: 'That looks like an invalid email address',
    userFail:
        'We could not find an account with that email address. Please register.',
    missingParameters:
        'Oops! Our engineers will fix this shortly. Please try again after sometime.',
    serviceError:
        'Oops! Our engineers are working on fixing this, please try again after sometime.',
    noInternet: 'Looks like you are not connected to the internet',
};

export const ResetPasswordForm = props => {
    const [email, setEmail] = useState('');
    const error = useSelector(selectForgotPasswordError);
    const isLoading = useSelector(selectForgotPasswordLoading);
    const linkSent = useSelector(selectLinkSent);
    const inputRef = useRef(null);
    const captchaRequired = useSelector(selectCaptchaRequired);
    const captchaToken = useSelector(selectCaptchaToken);
    
    const handleSubmit = () => {
        props.onForgotPasswordError(null);

        if (!email || !email.trim()) {
            props.onForgotPasswordError(errorResponses.missingEmail);
            return false;
        }

        if (!checkEmail(email)) {
            props.onForgotPasswordError(errorResponses.invalidEmail);
            return false;
        }

        props.onForgotPassword(email);
    };

    const handleKeyDown = event => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    };

    // put focus on the input when component mounts
    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleCaptchaSubmit = (token) => {
        props.handleCaptchaSubmit({ captchaToken: token });
        handleSubmit();
    }

    return (
        <div className="reset__wrapper" data-testid="forgot-password-form">
            <div
                className="btn_back"
                onClick={props.onBackPressed}
                data-testid="btn-back"
            >
                <img
                    src="/static/images/back-arrow.svg"
                    className="btn-back"
                    alt=""
                />{' '}
                Back to login
            </div>
            <div className="heading">Reset your password</div>
            <div className="subHeading">
                To obtain a new password, please enter your e-mail address and a
                link will be emailed to you.
            </div>

            {linkSent ? (
                <div className="link-sent-success">
                    We have sent you a recovery email. Please check your
                    mailbox.
                </div>
            ) : (
                <>
                    <div className="form__container">
                        <div className="formgroup">
                            <Input
                                id="input-email"
                                name="email"
                                type="email"
                                className="input-email"
                                placeholder="ex. john.smith@gmail.com"
                                label="Enter Email id"
                                value={email}
                                onChange={event => {
                                    props.onForgotPasswordError(null);
                                    setEmail(event.target.value);
                                }}
                                styleType="new"
                                error={error}
                                errorOptions={{
                                    'data-testid': 'error-forgot-email',
                                }}
                                inputProps={{
                                    ref: inputRef,
                                    onKeyDown: handleKeyDown,
                                    'data-testid': 'input-forgot-email',
                                }}
                            />
                        </div>
                    </div>

                    <Button
                        isLoading={isLoading}
                        type="btn-reset"
                        onClick={handleSubmit}
                        dataTestid="btn-reset"
                    >
                        Send Reset Email
                    </Button>
                </>
            )}
            {captchaRequired && !captchaToken ? (
                <SecurityModal submit={handleCaptchaSubmit} />
            ) : null}
        </div>
    );
};

ResetPasswordForm.propTypes = {
    onForgotPasswordError: PropTypes.func, // function to set error fields
    onForgotPassword: PropTypes.func, // forgot password api call handler
    onBackPressed: PropTypes.func, // on back button press handler
};
