import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Input } from '../../../../components/Input';
import { Button } from '../../../../components/Button';
import { checkEmail } from '../../../../../utils/checkEmail';
import { sendEvent } from '../../../../../utils/sendEvent';

import SecurityModal from '../SecurityModal';

import {
    selectLoginEmailError,
    selectLoginErrorStatus,
    selectLoginLoading,
    selectLoginPasswordError,
    selectCaptchaRequired,
    selectCaptchaToken
} from '../../selectors';

import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

export const loginErrorResponses = {
    missingEmail: 'Please enter the Email address',
    invalidEmail: 'That looks like an invalid email address',
    missingPassword: 'Please enter your password',
    invalidPassword: 'Please enter a valid password',
    userFail: 'We could not find an account with that email address.',
    passwordFail: 'Email or password is not correct.',
    missingParameters:
        'Oops! Our engineers will fix this shortly. Please try again after sometime.',
    serviceError:
        'Oops! Our engineers are working on fixing this, please try again after sometime.',
    noInternet: 'Looks like you are not connected to the internet',
    ipMismatch:
        'This account is not accessible from your IP address. Please contact your manager.',
};

export const LoginForm = props => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hidePassword, setHidePassword] = useState(true);
    const passwordInputRef = useRef(null);
    const emailInputRef = useRef(null);

    const isLoading = useSelector(selectLoginLoading);
    const emailError = useSelector(selectLoginEmailError);
    const passwordError = useSelector(selectLoginPasswordError);
    const errorStatus = useSelector(selectLoginErrorStatus);
    const captchaRequired = useSelector(selectCaptchaRequired);
    const captchaToken = useSelector(selectCaptchaToken);

    // focus should be on the email input box when the page loads
    useEffect(() => {
        if (emailInputRef && emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    // set the cursor to the end of the text when visibility is toggled.
    useEffect(() => {
        if (passwordInputRef && passwordInputRef.current) {
            const val = passwordInputRef.current.value.length;
            passwordInputRef.current.setSelectionRange(val, val);
        }
    }, [hidePassword]);

    const validateLogin = () => {
        // erase all errors
        props.setLoginErrors({});

        let hasError = false;
        const errorObj = {};

        if (!email || !email.trim()) {
            errorObj.email = loginErrorResponses['missingEmail'];
            hasError = true;
        }

        if (!hasError && !checkEmail(email.trim())) {
            errorObj.email = loginErrorResponses['invalidEmail'];
            hasError = true;
        }

        if (!password || !password.trim()) {
            errorObj.password = loginErrorResponses['missingPassword'];
            hasError = true;
        }

        if (hasError) {
            props.setLoginErrors(errorObj);
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        if (validateLogin()) {
            props.onLogin({
                email: email,
                password: password,
            });

            const label = `userId=${email}`;
            sendEvent('clickLoginLoggedOut', label);
        }
    };

    // Captures 'Enter' key press event
    const handleKeyDown = event => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    };

    const handleCaptchaSubmit = (token) => {
        props.handleCaptchaSubmit({ captchaToken: token });
        handleSubmit();
    }

    return (
        <div className="postjob__wrapper" data-testid="login-form">
            <div className="heading">
                {landingConfig.loginsection.formheading}
            </div>

            <div className="form__container">
                <div className="formgroup">
                    <Input
                        id="input-email"
                        name="email"
                        type="email"
                        className={`input-email ${
                            emailError ? 'error-input' : ''
                        }`}
                        placeholder="ex. john.smith@gmail.com"
                        label="Email id"
                        value={email}
                        onChange={event => {
                            props.setLoginErrors({ email: null });
                            setEmail(event.target.value);
                        }}
                        inputProps={{
                            ref: emailInputRef,
                            onKeyDown: handleKeyDown,
                            'data-testid': 'login-input-email',
                        }}
                        styleType="new"
                    />
                    {emailError ? (
                        <div
                            className="error error-email"
                            data-testid="error-login-email"
                        >
                            {emailError}{' '}
                            {errorStatus === 404 ? (
                                <span
                                    onClick={props.onRegister}
                                    className="btn-action underline"
                                    data-testid="looking-to-register"
                                >
                                    Looking to register?
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                <div className="formgroup password">
                    <div className="label-container">
                        <label>Password</label>
                        <div className="forgot_password">
                            <span
                                onClick={props.onForgotPassword}
                                data-testid="btn-forgot-password"
                            >
                                {landingConfig.loginsection.formpassword}
                            </span>
                        </div>
                    </div>
                    <Input
                        id="input-password"
                        name="password"
                        type={hidePassword ? 'password' : 'text'}
                        className={`input-password ${
                            passwordError ? 'error-input' : ''
                        }`}
                        placeholder="password here"
                        value={password}
                        onChange={event => {
                            props.setLoginErrors({ password: null });
                            setPassword(event.target.value);
                        }}
                        styleType="new"
                        inputProps={{
                            ref: passwordInputRef,
                            onKeyDown: handleKeyDown,
                            'data-testid': 'login-input-password',
                        }}
                    />
                    {passwordError ? (
                        <div
                            className="error error-password"
                            data-testid="error-login-password"
                        >
                            {passwordError}{' '}
                            {/* {errorStatus === 401 ? (
                                <span
                                    onClick={props.onForgotPassword}
                                    className="btn-action  underline"
                                    data-testid="recover-it"
                                >
                                    recover it.
                                </span>
                            ) : null} */}
                        </div>
                    ) : null}
                    <div className="password_options">
                        {hidePassword ? (
                            <img
                                className="icon-lock"
                                src="/static/images/lock.svg"
                                alt="password hidden"
                                onClick={() => {
                                    if (passwordInputRef.current) {
                                        passwordInputRef.current.focus();
                                    }
                                    setHidePassword(false);
                                }}
                            />
                        ) : (
                            <img
                                className="icon-unlock"
                                src="/static/images/unlock.svg"
                                alt="password visible"
                                onClick={() => {
                                    if (passwordInputRef.current) {
                                        passwordInputRef.current.focus();
                                    }
                                    setHidePassword(true);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>

            <Button
                type="btn-login"
                onClick={handleSubmit}
                isLoading={isLoading}
                dataTestid="btn-login"
            >
                Login
            </Button>

            <div className="no-account">
                {landingConfig.loginsection.formfooter}{' '}
                <span
                    className="btn-register"
                    onClick={props.onRegister}
                    data-testid="btn-register"
                >
                    {landingConfig.loginsection.formregister}
                </span>
            </div>
            {
                captchaRequired && !captchaToken ?
                <SecurityModal 
                    submit={handleCaptchaSubmit}
                /> : null
            }
        </div>
    );
};

LoginForm.propTypes = {
    setLoginErrors: PropTypes.func, // function to set login errors
    onLogin: PropTypes.func, // login api call handler
    onRegister: PropTypes.func, // register button click handler
    onForgotPassword: PropTypes.func, // forgot password click handler
};
