import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../Button';
import { Modal } from '../../../Modal';
import { postLeads } from '../../../../../services/postLeads';
import { updateProfile } from '../../../../../services/updateProfile';
import { sendEvent } from '../../../../../utils/sendEvent';
import { organizationLocation } from '../../../../../constants/organizationLocation';
import { Dropdown } from '../../../Dropdown';
import { FieldError } from '../../../FieldError';
import { noOfEmployees } from '../../../../../constants/noOfEmployees';

import './styles.scss';
import * as toast from '../../../../containers/Toast';

export const CalculusModal = props => {
    const { recruiterId, profile } = props;
    const localStorageItem = localStorage.getItem('sessionStatus');
    let storage = localStorageItem
        ? JSON.parse(localStorage.getItem('sessionStatus'))
        : {};
    const data = {
        recruiterId: recruiterId,
        additionalInfo: {
            enquiry_type: 'New',
        },
    };
    const salesMail = `Sales@${process.env.REACT_APP_BASE_DOMAIN_NAME}`;
    const [loading, setLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(true);
    const [validations, setValidations] = useState({});
    const [orgLocation, setOrgLocation] = useState(0);
    const [companySize, setCompanySize] = useState(0);
    const [apiResFail, setApiResFail] = useState(false);

    const newData = {
        ...data,
        productType: '2',
        activity: '1',
        source: '1',
    };

    useEffect(() => {
        if (!storage['calculusClick']) {
            try{
                postLeads(recruiterId, newData);
                storage = { ...storage, calculusClick: true };
                localStorage.setItem('sessionStatus', JSON.stringify(storage));
            }
            catch(e) {
                console.log(e);
            }
        }
    }, [recruiterId]);
    const demoLabel = `recid=${recruiterId},origin=Navbar`;

    async function requestDemo() {

        const { pass, vals } = validate();
        if (pass) {
            setLoading(true);
            setShowMsg(false);

            try {
                if (orgLocation)
                    await updateProfile(recruiterId, { location: orgLocation });
                await sendEnquiry();
            } catch (err) {
                console.log(err);
                setLoading(false);
                setApiResFail(true);
            }
        } else {
            setValidations(vals);
            return;
        }
    }


    async function sendEnquiry() {
        setLoading(true);
        try {
            const enquiryData = {
                ...data,
                productType: '2',
                activity: '2',
                source: '1',
                companyType: profile.recruiterType,
                companySize
            };
            sendEvent('clickCalculusDemo', demoLabel);
            await postLeads(recruiterId, enquiryData);
            storage = { ...storage, calculusEnquiry: true };
            localStorage.setItem('sessionStatus', JSON.stringify(storage));
            setLoading(false);
        } catch (err) {
            if(err.response.data && err.response.data.code == 4040){
                toast.warning("Please try after sometime");
            }
            console.log(err);
            setLoading(false);
        }
    }

    function validate() {
        const vals = {
            orgLocationReq:
                (!orgLocation || parseInt(orgLocation) <= 0) &&
                    (!profile.location || parseInt(profile.location) <= 0)
                    ? 'Please select Organization Location'
                    : null,
            companySizeReq:
                (!companySize) && (!profile.companySize || parseInt(profile.companySize) <= 0)
                    ? 'Please select company size'
                    : null,
        };

        let pass = true;
        for (let key of Object.keys(vals)) {
            if (vals[key]) {
                pass = false;
                break;
            }
        }
        return {
            pass,
            vals,
        };
    }

    function onLocationChange(val) {
        setOrgLocation(val);
    }

    function onCompanySizeChange(val) {
        setCompanySize(val);
    }

    let text = "";
    if ((!profile.location || +profile.location <= 0) && !profile.companySize) {
        text = "company size and work location";
    } else if (!profile.location) {
        text = "work location";
    } else if (!profile.companySize) {
        text = "company size";
    }

    return (
        <Modal onClose={props.onClose} classes={['calculusModalWrapper']}>
            {console.log(orgLocation, companySize)}
            <div className="head">CALCULUS</div>
            <div className="subhead">Salary Benchmarking & Talent MappingTool</div>
            <div className="calculusModalMain" data-testid="calculusModal">
                <div className="modal_content modal_left">
                    <div className="subHeading">
                    How much should you be paying for certain profiles in different geographical hotspots?
                    </div>
                    <div className="subHeading2">
                    Get detailed insights on salaries to decide compensation plan for the right candidate
                    </div>
                    <div className="section">
                        <table>
                            <tbody>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/modal_tick-green.svg"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points">
                                    View current and expected salary ranges for quick decision making
                                    </td>
                                </tr>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/modal_tick-green.svg"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points ">
                                    Bridge your gender pay differences
                                    </td>
                                </tr>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/modal_tick-green.svg"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points">
                                        Understand the Geographical Talent Hotspots
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="line_separation"></div>
                    <div className='inputs_main_container'>
                        {!storage['calculusEnquiry'] && showMsg ? <>
                            {    ((!profile.companySize) || (!profile.location || parseInt(profile.location) <= 0)) &&
                                <div className="subHeading">
                                    Share below details:
                                </div>
                            }
                            <div className="calculus_inputs_wrapper">
                                <div className="inputs_wrapper">
                                {    !profile.companySize &&
                                    <div className="search-location input_1">
                                        <Dropdown
                                            type="text"
                                            onChange={onCompanySizeChange}
                                            label="Company Size*"
                                            classes={['companySize']}
                                            name="companySize"
                                            options={noOfEmployees}
                                            blackCaret
                                            defaultValue={companySize}
                                            errorMsg={validations.companySizeReq}
                                        />
                                    </div>
                                }
                                {
                                    (!profile.location || parseInt(profile.location) <= 0) &&
                                    <div className="search-location input_2">
                                        <Dropdown
                                            type="text"
                                            onChange={onLocationChange}
                                            label="State*"
                                            classes={['orgLocation']}
                                            name="orgLocation"
                                            options={organizationLocation}
                                            blackCaret
                                            defaultValue={orgLocation}
                                            errorMsg={validations.orgLocationReq}
                                        />
                                    </div>
                                }
                                </div>
                            </div>
                        </> : null}
                    </div>
                    {!storage['calculusEnquiry'] ? (
                        <div className="success_message_wrapper">
                            <Button
                                isLoading={loading}
                                onClick={requestDemo}
                                classes={['green', 'lead-gen-btn']}
                                type={'calculusButton'}
                                dataTestid="demoButtonCalculus"
                            >
                                Request a Callback
                            </Button>
                        </div>
                    ) : null}
                    {storage['calculusEnquiry'] ? (
                        <p
                            className="info-text success_message_calculus"
                            data-testid="calculusSuccessMsg"
                        >
                            Thank you for showing interest in our Calculus
                            product. Our team will be in touch with you soon!
                        </p>
                    ) : null}
                </div>
                <div className="modal_right">
                    {/* <div> */}
                    <div className="main_text">See what Calculus offers</div>
                        <div className="modal_right_content_calculus">
                            <div className="calculus_videoArrowImgContainer">
                                {/* <img
                                    src="/static/images/down-arrow-bent.png"
                                    className="calculus_videoArrowImg"
                                    alt=""
                                /> */}
                                <iframe
                                className="calculus_videoIframe"
                                src="https://www.youtube.com/embed/0QzRC-1GonM"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                data-testid="iframeCalculus"
                            ></iframe>
                            </div>
                            
                            <div className="info_text info_calculus_contactHeading">
                                Connect with us at:
                            </div>
                            <div className="info_text info_calculus_contactInfo info_calculus_mail">
                                <div class="content_icon">
                                    <i class="icon-email_envelope"></i>
                                </div>
                                <a href={`mailto:${salesMail}`} className='sales_mail'>{salesMail}</a>
                            </div>
                            <div className="info_text info_calculus_contactInfo">
                                <div class="content_icon">
                                    <i class="icon-telephone"></i>
                                </div>
                                <span className="toll_free_text">Toll Free No.: 1800-103-7344</span>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </Modal>
    );
};

CalculusModal.propTypes = {
    onClose: PropTypes.func,
};
