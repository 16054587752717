import { createSlice } from '@reduxjs/toolkit';

// The initial state of the Landing container
export const initialState = {
    callbackUrl: null,
    actionType: null,
    showLoginForm: true,
    loginData: null,
    loginLoading: false,
    loginErrorStatus: null,
    loginEmailError: null,
    loginPasswordError: null,
    forgotPasswordLoading: false,
    forgotPasswordEmail: null,
    forgotPasswordError: null,
    forgotPasswordLinkSent: false,
    captchaRequired: false,
    captchaToken: '',
    registrationDomain: '',
    registrationOrg: '',
    suggestionsLoading: false,
    orgFetchPageNumber: 1,
    orgFetchPageContent: 500,
    orgFetchAllLoaded: false,
    orgFetchLoading: false,
    domainCompanies: [],
    unverifiedCompanies: [],
    registerData: null,
    registerLoading: false,
    registerError: {},
};

const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {
        setActionType(state, action) {
            state.actionType = action.payload.type;
        },

        unsetActionType(state, action) {
            state.actionType = null;
            state.registerData = null;
            state.registerLoading = null;
            state.registerError = {};
        },

        setShowLoginForm(state, action) {
            state.showLoginForm = action.payload.showLogin;
            state.forgotPasswordLinkSent = false;
            state.forgotPasswordError = null;
            state.loginEmailError = null;
            state.loginPasswordError = null;
        },

        setCallbackUrl(state, action) {
            state.callbackUrl = action.payload.callbackUrl;
        },

        setLoginErrors(state, action) {
            const errorObj = action.payload.data;

            if (errorObj.hasOwnProperty('email')) {
                state.loginEmailError = action.payload.data.email;
            }
            if (errorObj.hasOwnProperty('password')) {
                state.loginPasswordError = action.payload.data.password;
            }
        },

        loginUser(state, action) {
            state.loginData = action.payload.data;
            state.loginLoading = true;
        },

        loginUserSuccess(state, action) {
            state.loginLoading = false;
            state.captchaToken = '';
        },

        loginUserFailed(state, action) {
            state.loginData = null;
            state.loginLoading = false;

            if (action.payload.data) {
                const errorObj = action.payload.data;
                state.loginErrorStatus = errorObj.status;
                state.loginEmailError = errorObj.email;
                state.loginPasswordError = errorObj.password;
                state.captchaRequired = errorObj.captchaRequired;
                state.captchaToken = '';
            }
        },

        sendForgotPassword(state, action) {
            state.forgotPasswordEmail = action.payload.email;
            state.forgotPasswordLoading = true;
        },

        sendForgotPasswordSuccess(state, action) {
            state.forgotPasswordLinkSent = true;
            state.forgotPasswordLoading = false;
            state.forgotPasswordError = null;
            state.captchaToken = '';
            state.captchaRequired = false;
        },

        setForgotPasswordError(state, action) {
            state.forgotPasswordError = action.payload.error;
            state.forgotPasswordLoading = false;
            if (action.payload.errorObj) {
                state.captchaRequired = action.payload.errorObj.captchaRequired;
                state.captchaToken = '';
            }
        },

        setCaptchaToken(state, action) {
            state.captchaToken = action.payload.captchaToken;
        },

        resendForgotPassword(state) {},

        setRegistrationDomain(state, action) {
            state.registrationDomain = action.payload.domain;
        },

        registrationDomainResult(state, action) {
            state.domainCompanies = action.payload.data;
        },

        setOrganisation(state, action) {
            state.registrationOrg = action.payload.organisation;
            state.suggestionsLoading = true;
            state.orgFetchPageNumber = 1;
            state.orgFetchAllLoaded = false;
            state.unverifiedCompanies = [];
        },

        setOrganisationSuggestions(state, action) {
            const orgs = action.payload.data;

            if (orgs && orgs.length > 0) {
                if (orgs.length < state.orgFetchPageContent) {
                    state.orgFetchAllLoaded = true;
                } else {
                    state.orgFetchAllLoaded = false;
                }

                state.orgFetchPageNumber += 1;
            } else {
                state.orgFetchAllLoaded = true;
            }

            state.unverifiedCompanies = state.unverifiedCompanies.concat(orgs);
            state.suggestionsLoading = false;
            state.orgFetchLoading = false;
        },

        loadMoreOrganisation(state, action) {
            state.orgFetchLoading = true;
        },

        registerNewUser(state, action) {
            state.registerData = action.payload.data;
            state.registerLoading = true;
        },

        registerNewUserSuccess(state, action) {
            state.registerLoading = false;
        },

        registerNewUserFail(state, action) {
            state.registerError = action.payload.error;
            state.registerLoading = false;
        },

        setRegisterError(state, action) {
            const key = action.payload.key;
            const value = action.payload.value;

            if (!key && !value) {
                state.registerError = {};
            } else if (!key) {
                state.registerError = value;
            } else {
                const prevError = state.registerError;
                prevError[key] = value;
                state.registerError = prevError;
            }
        },
    },
});

export const { actions, reducer, name: sliceKey } = landingSlice;
