import React from 'react';
import { Link } from 'react-router-dom';

export const A = props => {
    if (props.isReact && props.href) {
        return (
            <Link to={props.href} {...props}>
                {props.children}
            </Link>
        );
    }
    return (
        <>
            {props.href ? (
                <a data-testid="link" {...props}>
                    {props.children}
                </a>
            ) : (
                <span data-testid="span" {...props}>
                    {props.children}
                </span>
            )}
        </>
    );
};
