import React from 'react';
import { SolutionsSectionItem } from '../SolutionsSectionItem';

import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

export const SolutionsSection = props => {
    return (
        <section className="solutions__section">
            <div className="wrapper">
                <div className="heading">
                    {landingConfig.section2.centerheading}
                </div>
                <div className="container">
                    <SolutionsSectionItem
                        number={1}
                        title={landingConfig.section2.title}
                        subtitle={landingConfig.section2.description}
                        imgSrc={landingConfig.section2.imagelink}
                        numberSrc={landingConfig.section2.numberlink}
                    />
                    <SolutionsSectionItem
                        number={2}
                        title={landingConfig.section3.title}
                        subtitle={landingConfig.section3.description}
                        imgSrc={landingConfig.section3.imagelink}
                        numberSrc={landingConfig.section3.numberlink}
                        isReverse={true}
                    />
                    <SolutionsSectionItem
                        number={3}
                        title={landingConfig.section4.title}
                        subtitle={landingConfig.section4.description}
                        imgSrc={landingConfig.section4.imagelink}
                        numberSrc={landingConfig.section4.numberlink}
                    />
                </div>
            </div>
        </section>
    );
};
