/**
 * Call Sales Section
 */

import React, { useState, useRef, useEffect } from 'react';
import { useOutsideClickDetector } from '../../../../../utils/useOutsideClickDetector';
import './styles.scss';

export const ContactSales = props => {
    const { contactMenuOpen } = props;
    let divRef = useRef(null);

    return (
        // <div className="contact_us_menu_main_wrapper">
			<>
			<span
                className="header-links" tabIndex="0" aria-label="Contact Us"
			>
				Contact Us
            </span>
            <div
                className={`profile-menu profile-menu-contact-sales ${contactMenuOpen ? 'active' : ''}`}
                ref={divRef}
            >
                <div class="contact-sales-menu inmenu">
					<div class="contact-sales-body">
						<div class="content_header">
							Connect with our Sales Team
						</div>
						<div class="content_body">
							<div class="content_main content_1">
								<div class="content_main_header">
									Sales Enquiries
								</div>
								<div class="content_main_body">
									<div class="content_main_body_text">
										<div class="content_icon">
											<i class="icon-telephone"></i>
										</div>
										1800-103-7344 <span>(9:30 AM to 6:30 PM)</span>
									</div>
									<div class="content_main_body_text">
										<div class="content_icon">
											<i class="icon-email_envelope"></i>
										</div>
										sales@{process.env.REACT_APP_BASE_DOMAIN_NAME}
									</div>
								</div>
							</div>
							<div class="content_main content_2">
								<div class="content_main_header">
									Customer Support
								</div>
								<div class="content_main_body">
									<div class="content_main_body_text">
										<div class="content_icon">
											<i class="icon-email_envelope"></i>
										</div>
										info@{process.env.REACT_APP_BASE_DOMAIN_NAME}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
			</>
    );
};
