import { createSlice } from '@reduxjs/toolkit';
import getCookie from '../utils/getCookie';

// The initial state of the App
export const initialState = {
    profile: (typeof window !== 'undefined' && window.profile) || {},
    recruiterId: (typeof window !== 'undefined' && window.recruiterId) || null,
    features: (typeof window !== 'undefined' && window.features) || {},
    postJobVersion: getCookie('code-version'),
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        dummy(state, action) {},
        changePremiumCredits(state, action) {
            state.profile.availableCredits = action.payload.premiumCredits;
        },
    },
});

export const { actions, reducer, name: sliceKey } = appSlice;
