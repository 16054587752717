import store from '../../../store';
import { actions } from './slice';

export function emit(content, loading) {
    store.dispatch({
        type: actions.setToast.type,
        payload: { content, loading },
    });
}

export function success(content, loading) {
    store.dispatch({
        type: actions.setToast.type,
        payload: { content, loading, type: 'success' },
    });
}

export function warning(content, loading) {
    store.dispatch({
        type: actions.setToast.type,
        payload: { content, loading, type: 'warning' },
    });
}
