export function checkPasswordValidations(password, showErrors = false) {
    const failCode = showErrors ? 2 : 0;

    var validations = {
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
    };

    if (password.length < 8) {
        validations[5] = failCode;
    }
    if (password.search(/[a-z]/) < 0) {
        validations[1] = failCode;
    }
    if (password.search(/[A-Z]/) < 0) {
        validations[2] = failCode;
    }
    if (password.search(/[0-9]/) < 0) {
        validations[3] = failCode;
    }
    if (password.search(/[@#$%^&*!()_+|~=\`{}:";'<>\/-]/) < 0) {
        validations[4] = failCode;
    }
    return validations;
}