import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '../../../Overlay';
import { endpoints } from '../../../../../constants/endpoints';
import { sendEvent } from '../../../../../utils/sendEvent';
import { A } from '../../../A';
import { NewTag } from '../../../NewTag';
import { checkFeature } from '../../../../../utils/checkFeature';


import './styles.scss';

export const MobileNavBarSignUp = props => {
    const [ exploreOpen, setExploreOpen] = useState(true)
    return (
        <Overlay outsideClick={props.onClose}>
            <div className="mobile-menu-container_sign_up">
                <div className="mobile-menu-content">
                    <div className="mobile-logo mobile-menu-item left">
                        <div className="domain_logo header_item">
                            <a
                                href={"#"}
                                className="logoLink"
                            >
                                <h1>{process.env.REACT_APP_BASE_DOMAIN}</h1>
                                <span>
                                    {process.env.REACT_APP_BASE_DOMAIN ===
                                    'hirist'
                                        ? '.tech'
                                        : '.com'}
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="close-mobile-menu mobile-menu-item right">
                        <span onClick={props.onClose}>
                            <i className="icon-close_icon"></i>
                        </span>
                    </div>
                    <div className="clearfix"></div>

                    <div className="menu-link-listing mobile-menu-item menu-section navigationHeader {{hiddenActions}}">
                    <ul>
                        <A
                            href="#"
                            onClick={() => {
                                setExploreOpen(!exploreOpen)
                            }}
                            className="menu-links"
                        >   
                            <li className="menu-list-item my-jobs">
                                Products
                                <i className={` ${exploreOpen ? "icon-down_arrow": "icon-right_arrow"}`}></i>
                            </li>
                            <div className={`abcabcabc_explore ${exploreOpen ? "" : "abcabcabc_display"}`}>
                                <ul className="section">
                                    <A
                                        href="/offerings?productHash=4"
                                        
                                        className="menu-links"
                                    >  
                                    <li className="inner_section">
                                        Premium Posting
                                    </li>
                                    </A>
                                    <A
                                        href="/offerings?productHash=1"
                                        
                                        className="menu-links"
                                    >  
                                        <li className="inner_section">
                                            Search Resume
                                        </li>
                                    </A>
                                    <A
                                        href="/offerings?productHash=2"
                                        
                                        className="menu-links"
                                    >  
                                    <li className="inner_section">
                                        Calculus - Salary Benchmarking
                                    </li>
                                    </A>
                                    <A
                                        href="/offerings?productHash=5"
                                        
                                        className="menu-links"
                                    >  
                                    <li className="inner_section">
                                        Employer Branding
                                    </li>
                                    </A>
                                    <A
                                        href="/offerings?productHash=3"
                                        
                                        className="menu-links"
                                    >  
                                    <li className="inner_section">
                                        Maven - Diversity
                                    </li>
                                    </A>
                                </ul>
                            </div>
                        </A>
                        <A
                            href={`https://www.${process.env.REACT_APP_BASE_DOMAIN_NAME}`}
                            onClick={() => {
                                setExploreOpen(false)
                            }}
                            className="menu-links"
                        >
                            <li className="menu-list-item my-jobs">
                                Looking for job ?
                            </li>
                        </A>

                    </ul>
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

MobileNavBarSignUp.propTypes = {
    onClose: PropTypes.func.isRequired,
    items: PropTypes.array,
    navItemClick: PropTypes.func.isRequired,
};
