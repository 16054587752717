import { createSlice } from '@reduxjs/toolkit';

// The initial state of the Toast container
export const initialState = {
    toastMessage: null,
    type: null,
    loading: false,
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setToast(state, action) {
            const options = action.payload;
            state.toastMessage = options.content;
            state.type = options.type;
            state.loading = options.loading;
        },
        removeToast(state) {
            state.toastMessage = null;
            state.type = null;
            state.loading = false;
        },
    },
});

export const { actions, reducer, name: sliceKey } = toastSlice;
