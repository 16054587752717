import axios from 'axios';
import getCookie from './getCookie';
import * as toast from '../app/containers/Toast';

let baseUrl = process.env.REACT_APP_BASE_URL;

const replaceDomainFrom = '.com';
const replaceDomainTo = '.tech';

if (
    typeof window !== 'undefined' &&
    window.location.origin.includes(replaceDomainTo) &&
    baseUrl.includes(replaceDomainFrom)
) {
    baseUrl = baseUrl.replace(replaceDomainFrom, replaceDomainTo);
}

const cookieName = process.env.REACT_APP_COOKIE_NAME;
const instance = axios.create({
    headers: {
        'X-CSRF-Token': typeof window !== 'undefined' ? window.csrfToken : '',
    },
    baseURL: baseUrl,
    withCredentials: true,
});

export const frontEndInstance = axios.create({
    withCredentials: true,
});

instance.interceptors.request.use(requestSuccess, requestError);
frontEndInstance.interceptors.request.use(requestSuccess, requestError);

function requestSuccess(config) {
    config.headers.Authorization = `Bearer ${getCookie(cookieName)}`;
    return config;
}

function requestError(error) {
    return Promise.reject(error);
}

instance.interceptors.response.use(responseSuccess, responseError);
frontEndInstance.interceptors.response.use(responseSuccess, responseError);

function responseSuccess(response) {
    return response;
}

function responseError(error) {
    if (error.response.status === 401) {
        toast.warning('user not authenticated');
        window.location.href =
            '/login?callbackUrl=' +
            encodeURIComponent(
                window.location.pathname +
                    (window.location.search ? window.location.search : ''),
            );
        return;
    } else if (error.response.status === 503) {
        toast.warning(
            'Oops...something went wrong. Our engineers are fixing the issue',
        );
    }
    return Promise.reject(error);
}

export default instance;
