export const noOfEmployees = [
    { label: 'Select Company Size', value: '' },
    { label: '1-10', value: 1 },
    { label: '11-50', value: 2 },
    { label: '51-200', value: 3 },
    { label: '201-500', value: 4 },
    { label: '501-1000', value: 5 },
    { label: '1001-5000', value: 6 },
    { label: '50001-10000', value: 7 },
    { label: '10000+', value: 8 },
];
