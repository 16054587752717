import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';

import './styles.scss';

// This component was needed at the time when the users were required to mandatorily change their account
// passwords due to a security upgrade.

export const ChangePasswordPrompt = props => {
    const [linkResent, setLinkResent] = useState(false);

    const handleResend = () => {
        setLinkResent(true);
        props.onResendLink();
    };

    return (
        <Modal classes={['change-password-modal']} onClose={props.onClose}>
            <div className="modal__container">
                <div className="modal__container--title">
                    We've updated our security procedures!
                </div>
                <div className="modal__container--subtitle">
                    Your privacy and security are important to us. We've
                    recently updated our security measures to make hirist.tech
                    safer and more secure for you. This change requires you to
                    reset your password.
                </div>

                <div className="modal__container--content">
                    Please reset your password using the link sent to your
                    email. If you did not receive any email, please check your
                    spam folder.
                </div>

                <div className="modal__container--actions">
                    {!linkResent ? (
                        <Button type="btn-resend" onClick={handleResend}>
                            Resend Email
                        </Button>
                    ) : (
                        <div className="link-sent-success">
                            Email sent Successfully!
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

ChangePasswordPrompt.propTypes = {
    onClose: PropTypes.func, // function to close modal
    onResendLink: PropTypes.func, // handler for resend password activation link
};
