import React, { useEffect, useState } from 'react';
import './styles.scss';
import { fetchToken } from '../../../../services/fetchToken';
import { Button } from '../../Button';

export const LoaderPropmpt = props => {
    const { recruiterId } = props;
    const [showButton, setShowButton] = useState(false);

    /*
        this is feature wise so if feature is off this is off
        if source is from .tech this is off 
        if .com comes in first show this to user
        send and api call
        if api fails, show a button to redirect to hirist.tech in logout state
        else add response of api as token in window href and redirect it to that page
        now we need to take a call between 2 promised both should be resolve first to go ahead


        new changes
            1. now direct redirect to .tech without maintaining session
               beacause existing session would have expired in 1 day and new session was not allowed to create
            2. delay time reduce to 2 second
    */

    // Add or update a query parameter
    function addOrUpdateQueryParam(url, key, value) {
        const urlObj = new URL(url);
        urlObj.searchParams.set(key, value);
        let newUrl = urlObj.toString();
        newUrl = newUrl.replace('.com', '.tech');
        return newUrl;
    }

    // Add a new query parameter or update an existing one

    useEffect(() => {
        document.body.style = 'overflow:hidden';

        async function addDelay(time) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve('done');
                }, time);
            });
        }

        async function getToken() {
            try {
                const response = await fetchToken(recruiterId);
                return response['data'];
            } catch (error) {
                console.log('token not found', error);
                throw error;
            }
        }

        async function redirectPage() {
            try {
                const promises = await Promise.all([
                    // getToken(),
                    addDelay(2000),
                ]);
                let newUrl = window.location.href;
                newUrl = newUrl.replace('.com', '.tech');
                window.location.href = newUrl;
                /*
                const p1 = promises[0];
                console.log(promises);
                console.log(p1.data.token);
                if (p1.data.token) {
                    console.log('token set ', p1.data.token);
                    // Get the current URL
                    let currentUrl = window.location.href;
                    let newUrl = addOrUpdateQueryParam(
                        currentUrl,
                        'token',
                        p1.data.token,
                    );
                    newUrl = addOrUpdateQueryParam(
                        newUrl,
                        'oldToken',
                        p1.data.oldToken,
                    );
                    console.log('new url is ', newUrl);
                    window.location.href = newUrl;
                } else {
                    let newUrl = addOrUpdateQueryParam(
                        window.location.href,
                        'token',
                        '',
                    );
                    window.location.href = newUrl;    
                }
                */
            } catch (error) {
                console.log(error);
                let newUrl = addOrUpdateQueryParam(
                    window.location.href,
                    'token',
                    '',
                );
                window.location.href = newUrl;
            }
        }
        redirectPage();
        setShowButton(true);
        return () => {
            document.body.style = 'overflow:auto';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recruiterId]);

    return (
        <div className="domain-migration-modal-container">
            <div className="domain-migration-loader-prompt-container">
                <img
                    alt="loader-prompt"
                    src="/static/images/illustration.webp"
                />
                <div className="loader-text-heading">Exciting news!</div>
                <div className="loader-text-content">
                    We're moving from recruit.hirist.com to{' '}
                    <span>recruit.hirist.tech</span> for an enhanced experience.
                </div>
                <div className="login-status-text">
                    Rest assured, your login session will remain uninterrupted
                </div>
                {showButton && (
                    <div className="redirect-btn-container">
                        <Button
                            classes={['redirection-btn']}
                            // type="white"
                            isLink={true}
                            href={addOrUpdateQueryParam(
                                window.location.href,
                                '',
                                '',
                            )}
                        >
                            Go to recruit.hirist.tech
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
