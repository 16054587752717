import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { actions } from './slice';

import {
    selectForgotPasswordEmail,
    selectLoginData,
    selectOrgFetchAllLoaded,
    selectOrgFetchPageContent,
    selectOrgFetchPageNumber,
    selectRegisterData,
    selectRegistrationDomain,
    selectRegistrationOrg,
    selectCaptchaToken
} from './selectors';

import { loginErrorResponses } from './components/LoginForm';
import { errorResponses as forgotPasswordErrors } from './components/ResetPasswordForm';
import { registerErrorResponses } from './components/RegisterModal';

import { submitLogin } from '../../../services/submitLogin';
import { submitRegister } from '../../../services/submitRegister';
import { submitForgotPassword } from '../../../services/submitForgotPassword';
import { fetchCompanyByDomain } from '../../../services/fetchCompanyByDomain';
import { fetchOrganisations } from '../../../services/fetchOrganisations';

import { setStorage } from '../../../utils/helpers';
import { removeQueryParams } from '../../../utils/removeQueryParams';
import { getQueryParams } from '../../../utils/getQueryParams';
import { setLoginToken } from '../../../utils/setLoginToken';
import { sendEvent } from '../../../utils/sendEvent';
import { callbackPath } from '../../../utils/callbackPath';
import { LandingModalTypes } from '.';
import { endpoints } from '../../../constants/endpoints';

export function* submitLoginUser() {
    const loginData = yield select(selectLoginData);
    const token = yield select(selectCaptchaToken);

    if (!loginData) {
        return;
    }

    try {
        const response = yield call(submitLogin, {
            ...loginData,
            token
        });

        const headers = response.headers;
        // setLoginToken(headers);

        let domainToBeCleared = process.env.REACT_APP_BASE_DOMAIN_NAME;
        // if (domainToBeCleared) {
        //     domainToBeCleared = '.' + domainToBeCleared;
        // }

        if (
            window.location.href.includes('.tech') &&
            domainToBeCleared.includes('.com')
        ) {
            domainToBeCleared = domainToBeCleared.replace('.com', '.tech');
        }

        document.cookie =
            window.seekerCookie +
            '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=' +
            '.' +
            domainToBeCleared +
            ';path=/;';

        setStorage('sessionStatus', {
            diversityAuth: null,
            diversityClick: null,
            searchClick: null,
            searchEnquiry: null,
            calculusClick: null,
            calculusEnquiry: null,
        });

        const params = getQueryParams(window.location.search);
        const callbackUrl = params.callbackUrl;

        if (response.data.data.isTwoStepAuthentication) {
            const callback = callbackUrl ? '?callbackUrl=' + callbackUrl : '';
            window.location = endpoints.twoStepAuth + callback;
            return;
        }

        if (callbackUrl) {
            removeQueryParams('callbackUrl');
            window.location = callbackPath(callbackUrl, domainToBeCleared);
        } else {
            window.location = endpoints.dashboard;
        }
    } catch (error) {
        let errorObj = {};
        let ipCode = '';

        if (
            error &&
            error.response &&
            error.response.status === 400 &&
            error.response.data.code === 4001
        ) {
            const label = `userId=${loginData.email}`;
            sendEvent('viewResetPopUpOnLanding', label);

            yield put(
                actions.setActionType({
                    type: LandingModalTypes.CHANGE_PASSWORD_PROMPT,
                }),
            );
            yield put(actions.loginUserSuccess());
            yield put(actions.sendForgotPassword({ email: loginData.email }));
            return;
        }

        if (error && error.response && error.response.status === 404) {
            errorObj.status = 404;
            errorObj.email = loginErrorResponses.userFail;
        } else if (error && error.response && error.response.status === 401) {
            ipCode = error.response.data.code;
            if (ipCode === 4011) {
                errorObj.password = loginErrorResponses.ipMismatch;
            } else {
                errorObj.status = 401;
                errorObj.password = loginErrorResponses.passwordFail;
            }
        } else if (error && error.response && error.response.status === 503) {
            errorObj.password = loginErrorResponses.serviceError;
        } else if (error && error.response && error.response.status === 422) {
            errorObj.password = loginErrorResponses.missingParameters;
        } else if (error && error.response && error.response.status === 400) {
            if (error.response.data.code === 4040)
                errorObj.captchaRequired = true;
        } else {
            errorObj.password = loginErrorResponses.noInternet;
        }
        yield put(actions.loginUserFailed({ data: errorObj }));
    }
}

export function* forgotPassword() {
    const email = yield select(selectForgotPasswordEmail);
    const token = yield select(selectCaptchaToken);

    if (!email) {
        return;
    }

    try {
        const response = yield call(submitForgotPassword, { email, token });

        yield put(actions.sendForgotPasswordSuccess());
    } catch (error) {
        // console.log(error.response);

        let errorObj = {};
        let errorMessage = '';

        if (error && error.response && error.response.status === 404) {
            errorMessage = forgotPasswordErrors.userFail;
        } else if (error && error.response && error.response.status === 401) {
            errorMessage = forgotPasswordErrors.serviceError;
        } else if (error && error.response && error.response.status === 503) {
            errorMessage = forgotPasswordErrors.serviceError;
        } else if (error && error.response && error.response.status === 422) {
            errorMessage = forgotPasswordErrors.missingParameters;
        } else if (error && error.response && error.response.status === 400) {
            if (error && error.response && error.response.data.code === 4040)
                errorObj.captchaRequired = true;
        } else {
            errorMessage = forgotPasswordErrors.noInternet;
        }

        yield put(
            actions.setForgotPasswordError({ error: errorMessage, errorObj }),
        );
    }
}

export function* fetchSuggestionsFromDomain() {
    const domain = yield select(selectRegistrationDomain);

    // if theres no domain reset suggestions
    if (!domain) {
        yield put(actions.registrationDomainResult({ data: [] }));
        return;
    }

    try {
        const response = yield call(fetchCompanyByDomain, {
            domain,
            statusNotEq: 0,
        });

        yield put(
            actions.registrationDomainResult({ data: response.data.data.data }),
        );
    } catch (error) {
        // console.log(error.response);
        yield put(actions.registrationDomainResult({ data: [] }));
    }
}

export function* fetchSuggestionsFromOrg() {
    const organisation = yield select(selectRegistrationOrg);
    const allLoaded = yield select(selectOrgFetchAllLoaded);

    if (!organisation) {
        yield put(actions.setOrganisationSuggestions({ data: [] }));
        return;
    }

    if (allLoaded) {
        return;
    }

    const pageNumber = yield select(selectOrgFetchPageNumber);
    const pageContent = yield select(selectOrgFetchPageContent);

    try {
        const response = yield call(fetchOrganisations, {
            statusNotEq: 0,
            nameSort: 1,
            displayNameLike: organisation,
            pageNumber,
            pageContent,
            targetDomain: process.env.REACT_APP_BASE_DOMAIN,
        });

        yield put(
            actions.setOrganisationSuggestions({
                data: response.data.data.data,
            }),
        );
    } catch (error) {
        // console.log(error.response);
        yield put(actions.setOrganisationSuggestions({ data: [] }));
    }
}

export function* registerNewUser() {
    const data = yield select(selectRegisterData);

    if (!data) {
        return;
    }

    try {
        const response = yield call(submitRegister, data);

        const headers = response.headers;
        // setLoginToken(headers);

        window.location.href = '/verify-email';
        yield put(actions.registerNewUserSuccess());
    } catch (error) {
        // console.log(error.response);

        const errorObj = {};
        if (error && error.response && error.response.status === 404) {
            errorObj.type = registerErrorResponses.userFail;
        } else if (error && error.response && error.response.status === 401) {
            errorObj.type = registerErrorResponses.passwordFail;
        } else if (error && error.response && error.response.status === 503) {
            errorObj.type = registerErrorResponses.serviceError;
        } else if (error && error.response && error.response.status === 422) {
            errorObj.type = registerErrorResponses.missingParameters;
        } else if (error && error.response && error.response.status === 409) {
            errorObj.email = registerErrorResponses.duplicate;
        } else {
            errorObj.type = registerErrorResponses.noInternet;
        }

        yield put(actions.registerNewUserFail({ error: errorObj }));
    }
}

export function* landingSaga() {
    yield all([
        yield takeLeading(actions.loginUser.type, submitLoginUser),
        yield takeLeading(actions.sendForgotPassword.type, forgotPassword),
        yield takeLeading(actions.resendForgotPassword.type, forgotPassword),
        yield takeLeading(
            actions.setRegistrationDomain.type,
            fetchSuggestionsFromDomain,
        ),
        yield takeLeading(
            actions.setOrganisation.type,
            fetchSuggestionsFromOrg,
        ),
        yield takeLeading(
            actions.loadMoreOrganisation.type,
            fetchSuggestionsFromOrg,
        ),
        yield takeLeading(actions.registerNewUser.type, registerNewUser),
    ]);
}
