import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FieldError } from '../FieldError';

export const Dropdown = props => {
    let error = props.error || props.errorMsg;
    const styleType = props.styleType || '';
    return (
        <>
            <select
                name={props.name}
                id={props.id}
                className={`r-dropdown ${styleType} ${(
                    props.classes || []
                ).join(' ')} ${props.blackCaret ? 'black-caret' : ''} ${
                    error ? 'on-error' : ''
                }`}
                onChange={ev => {
                    props.onChange(ev.target.value, ev);
                }}
                value={props.defaultValue}
                {...(props.selectProps || {})}
            >
                {props.options.map(item => {
                    if (!item.isCategory) {
                        return (
                            <option
                                key={item.value}
                                disabled={item.disabled}
                                value={item.value}
                            >
                                {item.label}
                            </option>
                        );
                    } else {
                        return (
                            <optgroup key={item.id} label={item.label}>
                                {item.items.map(optionObj => {
                                    return (
                                        <option
                                            key={optionObj.value}
                                            value={optionObj.value}
                                        >
                                            {optionObj.text}
                                        </option>
                                    );
                                })}
                            </optgroup>
                        );
                    }
                })}
            </select>
            {props.errorMsg ? <FieldError>{props.errorMsg}</FieldError> : null}
        </>
    );
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.any,
            isCategory: PropTypes.bool,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.string),
    defaultValue: PropTypes.string,
    selectProps: PropTypes.object,
    styleType: PropTypes.string,
};
