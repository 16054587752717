import { endpoints } from '../constants/endpoints';

export function callbackPath(callbackUrl, domain) {
    let isAllowed = false;
    let path = endpoints.dashboard; // default path is of dashboard

    try {
        try {
            isAllowed = new URL(callbackUrl).hostname.endsWith(`.${domain}`);
        } catch (err) {
            console.log('error in creating url', err);
        }

        if (isAllowed) {
            path = decodeURIComponent(callbackUrl);
        } else {
            path =
                window.location.origin +
                '/' +
                decodeURIComponent(callbackUrl).replace('/', '');
        }
    } catch (err) {
        console.log(
            'error while getting callback path ',
            callbackUrl,
            domain,
            err,
        );
    }

    return path;
}
