/**
 *
 * Footer
 *
 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { otherDomains } from '../../../constants/domains';
import { endpoints } from '../../../constants/endpoints';

import './styles.scss';
import CSATSurvey from '../CSATSurvey';

const footerConfig = require(`../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/footer.json`);

const bottomBarItems = [
    {
        title: 'Terms',
        link: endpoints.terms,
    },
    {
        title: 'Privacy',
        link: endpoints.privacy,
    },
];

const getOtherDomainsItem = (isNew = false, item) => {
    let title = `${item.heading} - ${item.alias}`;
    if (isNew) {
        title = `<span class="bold">${item.heading}</span> ${item.newSubHeading}`;
    }
    return {
        title,
        link: item.link,
        target: '_blank',
    };
};

const getItems = (isNew = false) => {
    const items = [
        {
            title: 'Company',
            children: [
                {
                    title: 'Home',
                    link: endpoints.dashboard,
                    classes: ['homeLink'],
                },
                {
                    title: 'About Us',
                    link: endpoints.about,
                },
                {
                    title: 'Contact Us',
                    link: endpoints.contact,
                },
            ],
        },
        {
            title: 'Information',
            children: [
                {
                    title: 'Terms',
                    link: endpoints.terms,
                },
                {
                    title: 'Privacy',
                    link: endpoints.privacy,
                },
            ],
        },
        {
            title: 'Other Portals',
            children: otherDomains.map(item =>
                getOtherDomainsItem(isNew, item),
            ),
        },
    ];

    if (!isNew) {
        return items;
    }

    return [items[0], items[2]];
};

export function Footer(props) {
    const { useNewLandingDesign } = props;
    const baseDomain = process.env.REACT_APP_BASE_DOMAIN;
    const baseDomainName = process.env.REACT_APP_BASE_DOMAIN_NAME;

    const [stickyStyle, setStickyStyle] = useState({ bottom: '10px' }); // Default position
    const footerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
          if (footerRef.current) {
            const footerRect = footerRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
    
            if (footerRect.top < viewportHeight) {
              // Footer is visible, move sticky div above it
              const newBottom = viewportHeight - footerRect.top + 10; // Distance from footer
              setStickyStyle({ bottom: `${newBottom}px` });
            } else {
              // Footer is not visible, reset to default position
              setStickyStyle({ bottom: '10px' });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
    
        handleScroll(); // Initial check
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleScroll);
        };
      }, []);

    const logoColumn = (
        <div className="company__info">
            <div className="company__info--logo">
                <img
                    className="logo"
                    src="/static/images/new-logo-icon.png"
                    alt="logo"
                />
                <a href={endpoints.dashboard} className="logoLink">
                    {process.env.REACT_APP_BASE_DOMAIN !== 'hirist' ? (
                        <>
                            <h1>{process.env.REACT_APP_BASE_DOMAIN}</h1>
                            <span>.com</span>
                            <p className="logo_tagline"></p>
                        </>
                    ) : (
                        <img
                            className={`hirist-tech-logo ${
                                useNewLandingDesign
                                    ? 'black-logo'
                                    : 'white-logo'
                            }`}
                            src="/static/images/hirist-tech-logo.webp"
                            alt="hirist-tech-logo"
                        />
                    )}
                </a>
            </div>
            <hr className="row-seperator" align="left" />
            <div className="company__info--socials">
                <p>Follow us on</p>
                <div>
                    <div className="social-media-icon facebook" key="facebook">
                        <a href={footerConfig.socialMedia.facebook}>
                            <img
                                src="/static/images/facebook_black.png"
                                alt="facebook"
                            />
                        </a>
                    </div>
                    <div className="social-media-icon linkedin" key="linkedin">
                        <a href={footerConfig.socialMedia.linkedIn}>
                            <img
                                src="/static/images/linkedin_black.png"
                                alt="facebook"
                            />
                        </a>
                    </div>
                    <div className="social-media-icon twitter" key="twitter">
                        <a href={footerConfig.socialMedia.twitter}>
                            <img
                                src="/static/images/twitter_black.png"
                                alt="facebook"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

    const columnItems = getItems(true).map((item, indx) => {
        return (
            <div key={indx} className="column__container">
                <div className="column__container--title">{item.title}</div>
                <div className="column__container--body">
                    {item.children.map((subItem, subIndx) => {
                        return (
                            <div className="column__subitem" key={subIndx}>
                                <span className="column__subitem--bullet">
                                    &#8226;
                                </span>
                                {subItem.target ? (
                                    <a
                                        className="column__subitem--link"
                                        href={subItem.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        dangerouslySetInnerHTML={{
                                            __html: subItem.title,
                                        }}
                                    ></a>
                                ) : (
                                    <a
                                        className="column__subitem--link"
                                        href={subItem.link}
                                        dangerouslySetInnerHTML={{
                                            __html: subItem.title,
                                        }}
                                    ></a>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    });

    const newFooter = (
        <div className="newfooter">
            <div className="newfooter__container">
                <div className="newfooter__container--links">
                    {logoColumn}
                    <div className="container">
                        {columnItems}
                        <div className="column__container">
                            <div className="sales">
                                <div className="column__container--title">
                                    Sales Enquiries
                                </div>
                                <div className="column__container--body">
                                    <a
                                        className="sales-email"
                                        href={`mailto:sales
                                        @${baseDomainName}`}
                                    >
                                        sales@
                                        {baseDomainName}
                                    </a>
                                    <div className="sales-contact">
                                        <a href="tel:18001037344">
                                            1800 103 7344
                                        </a>
                                        <span>
                                            Toll Free no. (9:30 AM to 6:30 PM)
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="support">
                                <div className="column__container--title">
                                    Customer Support
                                </div>
                                <div className="column__container--body">
                                    <a
                                        className="support-contact"
                                        href={`mailto:info@${baseDomainName}`}
                                    >
                                        info@
                                        {baseDomainName}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="newfooter__container--bottom">
                    {bottomBarItems.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bottom__item--title"
                                >
                                    {item.title}
                                </a>
                                {index !== bottomBarItems.length - 1 ? (
                                    <div className="bottom__item--bullet">
                                        &#8226;
                                    </div>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
    return !useNewLandingDesign ? (
        <div ref={footerRef} className="footer">
            <div className="footer-column-container">
                {getItems().map((item, i) => (
                    <div key={i} className="footer-column">
                        <ul className="footer-links-list">
                            <li className="footer-link-item header-link">
                                <span className="link-item">{item.title}</span>
                            </li>
                            {item.children.map((subItem, si) => (
                                <li key={si} className="footer-link-item">
                                    <a
                                        className={[
                                            (subItem.classes || []).join(','),
                                        ]}
                                        target={subItem.target}
                                        href={subItem.link}
                                    >
                                        {subItem.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}

                <div className="footer-column platforms">
                    <ul className="footer-links-list">
                        <li className="footer-link-item header-link">
                            <span className="link-item">Sales Enquiries</span>
                        </li>
                        <li className="footer-link-item">
                            <span className="link-item">
                                Toll Free No. (9:30 AM to 6:30 PM)
                            </span>
                        </li>
                        <li className="footer-link-item">
                            <span className="icon">
                                <i className="icon-telephone"></i>
                            </span>
                            <span className="font-16 link-item">
                                1800-103-7344
                            </span>
                        </li>
                        <li className="footer-link-item">
                            <span className="icon">
                                <i className="icon-email_envelope"></i>
                            </span>
                            <span className="font-16 link-item">
                                sales@{baseDomainName}
                            </span>
                        </li>
                        <li className="footer-link-item mt-10">
                            <span className="link-item">Customer Support</span>
                        </li>
                        <li className="footer-link-item">
                            <span className="icon">
                                <i className="icon-email_envelope"></i>
                            </span>
                            <span className="font-16 link-item">
                                info@{baseDomainName}
                            </span>
                        </li>
                    </ul>
                </div>

                <div className="footer-social-media">
                    <div className="social-media-icon facebook">
                        <a href={footerConfig.socialMedia.facebook}>
                            <i className="icon-facebook"></i>
                        </a>
                    </div>
                    <div className="social-media-icon linkedin">
                        <a href={footerConfig.socialMedia.linkedIn}>
                            <i className="icon-linkedin"></i>
                        </a>
                    </div>
                    <div className="social-media-icon twitter">
                        <a href={footerConfig.socialMedia.twitter}>
                            <i className="icon-twitter"></i>
                        </a>
                    </div>
                </div>

                <div className="footer-copy-text">
                    <p>
                        {baseDomainName} &copy; {new Date().getFullYear()}. All
                        rights reserved.
                    </p>
                </div>
            </div>
            <div id="csat-survey-root" className="sticky-div" style={stickyStyle}>
                <CSATSurvey {...props} />
            </div>
        </div>
    ) : (
        newFooter
    );
}

Footer.propTypes = {
    useNewLandingDesign: PropTypes.bool,
};
