import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import {
    selectOrgFetchAllLoaded,
    selectOrgFetchLoading,
    selectSuggestionsLoading,
} from '../../selectors';

import { Tooltip } from '../../../../components/Tooltip';
import { Loader } from '../../../../components/Loader';

import './styles.scss';

export const CustomDropdown = props => {
    const [showList, setShowList] = useState(false);
    const [lockInput, setLockInput] = useState(false);
    const inputRef = useRef(null);
    const listRef = useRef(null);

    const isLoadingSuggestions = useSelector(selectSuggestionsLoading);
    const isScrollLoading = useSelector(selectOrgFetchLoading);
    const allLoaded = useSelector(selectOrgFetchAllLoaded);

    const placeholderText = props.placeholder || 'Select from dropdown';

    const handleInputChange = event => {
        const value = event.target.value;
        props.onInputChange(value);
    };

    // When user clicks on any item from the dropdown
    const handleItemSelected = event => {
        setLockInput(true);
        setShowList(false);

        const id = event.target.getAttribute('data-id');
        const companyId = event.target.getAttribute('data-companyid');
        const name =
            event.target.getAttribute('data-value') || props.inputValue;
        props.onOptionSelected({ id, companyId, name });
    };

    // keeps track of when user has scrolled through the current page of suggestions
    const handleScrollChange = event => {
        if (!isScrollLoading && !allLoaded && listRef && listRef.current) {
            if (
                listRef.current.clientHeight + listRef.current.scrollTop >=
                listRef.current.scrollHeight
            ) {
                props.loadMore();
            }
        }
    };

    // on clicking btn-change, user should again be able to change input
    const handleOnChange = () => {
        setLockInput(false);
    };

    const handleOnInputFocus = () => {
        setShowList(true);
        setLockInput(false);
    };

    const handleOnInputBlur = event => {
        setShowList(false);
        setLockInput(true);
        props.onInputChange(props.value);
    };

    // Focus on input after it gets enabled again
    useEffect(() => {
        if (props.value && !lockInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [lockInput]);

    const itemsList = props.options.map(item => {
        return (
            <div
                key={item.id}
                className="dropdown-list-item"
                onMouseDown={handleItemSelected}
                data-id={item.id}
                data-value={item.displayName}
                data-companyid={item.companyId}
            >
                {item.displayName}
            </div>
        );
    });

    const suggestedItemsList = props.suggestedOptions.map(item => {
        return (
            <div
                key={item.id}
                className="dropdown-list-item"
                onMouseDown={handleItemSelected}
                data-id={item.id}
                data-value={item.displayName}
                data-companyid={item.companyId}
            >
                {item.displayName}{' '}
                <span>
                    (suggested
                    <i
                        data-tip="We suggest the organization name on the basis of your email id"
                        data-for="hint"
                    >
                        <img
                            src="/static/images/info.svg"
                            className="icon-information"
                            alt=""
                        />
                        <Tooltip id="hint" />
                    </i>
                    )
                </span>
            </div>
        );
    });

    return (
        <div className="dropdown-wrapper">
            {props.label ? (
                <div className="dropdown-header">
                    <label>{props.label}</label>
                </div>
            ) : null}

            <div className="dropdown-content">
                <input
                    type="text"
                    ref={inputRef}
                    value={lockInput ? props.value : props.inputValue}
                    className={`dropdown-content-input ${
                        props.error ? 'on-error' : ''
                    } ${isLoadingSuggestions ? 'pad-right' : ''} ${
                        props.value && lockInput ? 'pad-right-lock' : ''
                    }`}
                    onChange={handleInputChange}
                    placeholder={placeholderText}
                    onFocus={handleOnInputFocus}
                    onBlur={handleOnInputBlur}
                    disabled={props.value && lockInput ? true : false}
                    data-testid="input-custom-organisation"
                />
                {!lockInput && isLoadingSuggestions ? (
                    <Loader type={2} />
                ) : null}

                {props.value && lockInput ? (
                    <div
                        className="btn-change"
                        onClick={handleOnChange}
                        data-testid="btn-change"
                    >
                        Change
                    </div>
                ) : null}
            </div>

            {props.inputValue || props.suggestedOptions.length ? (
                <div className={`dropdown-list ${showList ? 'open' : 'close'}`}>
                    <div
                        ref={listRef}
                        className="dropdown-list-content"
                        onScroll={handleScrollChange}
                    >
                        {suggestedItemsList}
                        {itemsList}
                        {isScrollLoading ? (
                            <div className="list-loader">
                                <Loader type={2} />
                            </div>
                        ) : null}
                    </div>
                    {props.inputValue ? (
                        <div
                            className="dropdown-list-action"
                            onMouseDown={handleItemSelected}
                            data-testid="btn-add"
                        >
                            <span>+ Add {props.inputValue}</span>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

CustomDropdown.propTypes = {
    suggestionText: PropTypes.string, // suggestion text is the placeholder for suggested companies tooltip
    suggestedOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            companyId: PropTypes.any,
            displayName: PropTypes.any,
        }),
    ), // suggested options is a list of company suggestions from the domain of the user
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            companyId: PropTypes.any,
            displayName: PropTypes.any,
        }),
    ).isRequired, // this is the array which contains the company options based on what the user has entered
    onInputChange: PropTypes.func, // on input change in the input box
    classes: PropTypes.arrayOf(PropTypes.string), // classes array for the component
    placeholder: PropTypes.string, // placeholder for the input box
    error: PropTypes.string, // error string
    label: PropTypes.string, // label for the input component
    inputValue: PropTypes.string, // value of the input box
};
