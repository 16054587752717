import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';

// react slick depends on slick-carousel for the following imports.
// Slick carousel in turn depends on jQuery. So if you dont want jQuery dependencies,
// then just copy all the following and the related imports to your project

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);
const logos = landingConfig.companyLogos;

export const CompanyShowcase = props => {
    // handle for ssrlogos
    var w = global ? {} : window;
    const [width, setWidth] = useState(w.innerWidth || 1440);
    const [maxItems, setMaxItems] = useState(logos.length);

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        const updateScreenSize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenSize);

        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    useEffect(() => {
        // itemsize refers to width occupied by an item of the carousel. Refer to styles for details
        const itemSize = width < 768 ? 100 : 160;

        // minimum number of items that has to be shown
        const minNumberOfItems = width < 768 ? (width >= 500 ? 4 : 3) : 7;

        // number of items that can be shown at a particular width
        const numberOfItems = Math.min(
            Math.floor(width / (itemSize + 20)),
            minNumberOfItems,
        );
        setMaxItems(numberOfItems);
    }, [width]);

    // settings for react-slick
    const settings = {
        infinite: true,
        slidesToShow: maxItems,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        cssEase: 'linear',
    };

    return (
        <section className="showcase__section">
            <div className="showcase__container">
                <div className="heading">{landingConfig.section1.title}</div>

                <div className="company__carousel">
                    <Slider {...settings}>
                        {logos.concat(logos).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`carousel__item ${item.company}`}
                                >
                                    <img src={item.logo} alt={item.company} />
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    );
};
