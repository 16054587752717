import React, { useState } from 'react';
import './styles.scss';
import { Modal } from '../../../../components/Modal';
const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

export const Testimonial = () => {
    const [currentTestimonailNo, setCurrentTestimonialNo] = useState(1);
    const [showTestimonialModal, setShowTestimonialModal] = useState(false);
    const testimonials = landingConfig['testimonials'];
    const NO_OF_TESTIMONIALS = testimonials.length;

    const currentTestimonial = testimonials[currentTestimonailNo - 1];

    const changeTestimonail = change => {
        if (
            currentTestimonailNo + change < 1 ||
            currentTestimonailNo + change > NO_OF_TESTIMONIALS
        ) {
            return;
        }
        setCurrentTestimonialNo(currentTestimonailNo + change);
    };

    const openTestimonialModal = () => {
        setShowTestimonialModal(true);
    };

    const closeTestimonialModal = () => {
        setShowTestimonialModal(false);
    };

    if (NO_OF_TESTIMONIALS === 0) {
        return <></>;
    }

    return (
        <section className="testimonial-section">
            <div className="testimonial-heading">What our customer say</div>
            <div className="testimonial-content-container">
                <i
                    className={`icon-right_arrow slide-left ${
                        currentTestimonailNo === 1 ? 'disabled' : ''
                    }`}
                    onClick={() => changeTestimonail(-1)}
                ></i>
                <div className="testimnonial-content">
                    <div
                        className={`testimonail-text ${
                            currentTestimonial.thumbnailSrc
                                ? 'left-aligned'
                                : ''
                        }`}
                    >
                        {currentTestimonial.thumbnailSrc ? (
                            <div
                                className="video-testimonial-thumbnail"
                                onClick={openTestimonialModal}
                            >
                                <img
                                    style={{ border: "6px solid #d8d8d8", borderRadius: "20px" }}
                                    src={currentTestimonial.thumbnailSrc}
                                    alt="video-src"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="">
                            {currentTestimonial.text}
                            {currentTestimonial.thumbnailSrc ? (
                                <div className="brand-logo">
                                    <img
                                        src={currentTestimonial.logoUrl}
                                        alt=""
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    {/* <div className="slide-testmonial-btn">
                        <i
                            className={`icon-right_arrow slide-left ${
                                currentTestimonailNo === 1 ? 'disabled' : ''
                            }`}
                            onClick={() => changeTestimonail(-1)}
                        ></i>
                        <i
                            className={`icon-right_arrow slide-right ${
                                currentTestimonailNo === NO_OF_TESTIMONIALS
                                    ? 'disabled'
                                    : ''
                            }`}
                            onClick={() => changeTestimonail(1)}
                        ></i>
                    </div> */}
                    {!currentTestimonial.thumbnailSrc ? (
                        <div className="brand-logo">
                            <img src={currentTestimonial.logoUrl} alt="" />
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="current-selected-icons">
                        {/* <img src="" alt="sliding-icon" />
                        <img src="" alt="sliding-icon" />
                        <img src="" alt="sliding-icon" /> */}
                        {Array.from(
                            { length: NO_OF_TESTIMONIALS },
                            (_, i) => i + 1,
                        ).map(value => {
                            return (
                                <div
                                    className={`current-selected-icon ${
                                        currentTestimonailNo === value
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    {' '}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <i
                    className={`icon-right_arrow slide-right ${
                        currentTestimonailNo === NO_OF_TESTIMONIALS
                            ? 'disabled'
                            : ''
                    }`}
                    onClick={() => changeTestimonail(1)}
                ></i>
            </div>
            {showTestimonialModal && currentTestimonial.videoSrc ? (
                <Modal
                    onClose={closeTestimonialModal}
                    classes={['testimonail-video-modal']}
                >
                    <iframe
                        title="testimonial-video"
                        src={currentTestimonial.videoSrc}
                        height="540"
                        width="960"
                    ></iframe>
                </Modal>
            ) : (
                <></>
            )}
        </section>
    );
};
