import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

export const Tooltip = props => {
    const options = {
        className: ['tooltip', props.className || ''].join(' '),
        effect: 'solid',
        place: 'bottom',
        backgroundColor: '#efefef',
        textColor: '#2b2b2b',
        multiline: true,
        ...props.options,
        id: props.id,
    };
    if (props.getContent) {
        options.getContent = props.getContent;
    }
    return <ReactTooltip {...options} />;
};

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.objectOf(PropTypes.string),
};
