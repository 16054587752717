import React from 'react';

import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

export const AppBanner = () => {
    return (
        <section className="appbanner__section">
            <div className="appbanner__wrapper">
                <div className="wrapper">
                    <div className="container">
                        <div className="container__body">
                            <div className="heading">
                                {landingConfig.section7.centerheading}
                            </div>
                            <div className="listitem">
                                <img
                                    src="/static/images/arrow_right.svg"
                                    alt=""
                                />
                                <p>{landingConfig.section7.point1}</p>
                            </div>
                            <div className="listitem">
                                <img
                                    src="/static/images/arrow_right.svg"
                                    alt=""
                                />
                                <p>{landingConfig.section7.point2}</p>
                            </div>
                            <div className="listitem">
                                <img
                                    src="/static/images/arrow_right.svg"
                                    alt=""
                                />
                                <p>{landingConfig.section7.point3}</p>
                            </div>
                            <div className="listitem">
                                <img
                                    src="/static/images/arrow_right.svg"
                                    alt=""
                                />
                                <p>{landingConfig.section7.point4}</p>
                            </div>

                            <div className="download-btns">
                                <p>{landingConfig.section7.download}</p>
                                <a
                                    href={landingConfig.section7.ioslink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="img_app_store"
                                        src="/static/images/app-store.png"
                                        alt="app store"
                                    />
                                </a>
                                <a
                                    href={landingConfig.section7.androidlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="img_play_store"
                                        src="/static/images/play-store.png"
                                        alt="play store"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="container__image">
                            <img
                                src={landingConfig.section7.imageLink}
                                srcSet={landingConfig.section7.imageSrcSet}
                                alt="screenshot"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
