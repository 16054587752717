import React from 'react';
import './styles.scss';

export const NewTag = props => {
    return (
        <div className={`new-tag ${props.className}`}>
            {!props.withoutArrow ? <span></span> : null}
            New
        </div>
    );
};
