import React from 'react';
import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

const items = landingConfig.section6.category;

export const TechStackBanner = props => {
    let updazzClass = null;
    if (items.length > 8) {
        updazzClass = 'updazz';
    }

    return (
        <section>
            <div className="tech__banner">
                <div className="wrapper">
                    <div className="container">
                        <div className="heading">
                            {landingConfig.section6.centerheading}
                        </div>

                        <div className="grid-wrapper">
                            <img
                                className={`bg-corner-right ${updazzClass}`}
                                src="/static/images/corner.svg"
                                alt=""
                            />
                            <div className="grid__container">
                                {items.map((item, index) => {
                                    return (
                                        <div key={index} className="grid__item">
                                            <img
                                                className={`grid__item--img ${
                                                    process.env
                                                        .REACT_APP_BASE_DOMAIN ===
                                                    'hirist'
                                                        ? null
                                                        : 'wd100'
                                                }`}
                                                src={item.image}
                                                alt=""
                                            />
                                            <div>
                                                <p
                                                    className={`title ${
                                                        item.class
                                                            ? item.class
                                                            : ''
                                                    }`}
                                                >
                                                    {item.title}
                                                </p>
                                                {item.subtitle ? (
                                                    <p className="sub-title">
                                                        {item.subtitle}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <img
                                className={`bg-corner-left ${updazzClass}`}
                                src="/static/images/corner.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
