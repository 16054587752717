import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export const SolutionsSectionItem = props => {
    const domRef = useRef();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // if the browser does not support Intersection Observer, then show the items without animation.
        if (
            !('IntersectionObserver' in window) ||
            !('IntersectionObserverEntry' in window) ||
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
        ) {
            setIsVisible(true);
            return;
        }

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setIsVisible(true);

                observer.unobserve(domRef.current);
            }
        });

        observer.observe(domRef.current);

        return () => observer.unobserve(domRef.current);
    }, []);

    return (
        <div
            ref={domRef}
            className={`solutions__section container__item ${
                props.isReverse ? 'reverse' : ''
            }`}
        >
            <div className="container__item--heading">
                <div className="title">
                    <img className="img-number" src={props.numberSrc} alt="" />
                    <p className="title_text">{props.title}</p>
                </div>
                <div className="subtitle">{props.subtitle}</div>
            </div>
            <div className="container__item--image">
                <img
                    src={props.imgSrc}
                    className={`${props.number === 1 ? 'one' : ''} ${
                        isVisible
                            ? props.isReverse
                                ? 'reverse-animate'
                                : 'animate'
                            : ''
                    }`}
                    alt="illustration"
                />
            </div>
        </div>
    );
};

SolutionsSectionItem.propTypes = {
    title: PropTypes.string.isRequired, // Heading
    subtitle: PropTypes.string.isRequired, // Sub heading
    imgSrc: PropTypes.string.isRequired, // hero illustration
    numberSrc: PropTypes.string.isRequired, // serial number under title
    isReverse: PropTypes.bool,
};
