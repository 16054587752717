export function getQueryParams(str) {
    str = str.replace('?', '');
    const params = str.split('&').reduce((p, c) => {
        const [key, val] = c.split('=');
        if (key) {
            p[key] = val;
        }
        return p;
    }, {});

    return params;
}
