import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NewTag } from '../../../NewTag';
import { A } from '../../../A';
import './styles.scss';

export const NestedMenuItem = ({ item, onClick, navItemClick }) => {
    const [subMenu, setSubmenu] = useState(false);
    return (
        <li
            onClick={onClick}
            onMouseEnter={() => setSubmenu(true)}
            onMouseLeave={() => setSubmenu(false)}
            className="nested-menu-list-item"
        >
            {item.isReact ? (
                <NavLink to={item.link} activeClassName="active">
                    {item.title}
                </NavLink>
            ) : (
                <A className="header-links" href={item.link}>
                    {item.title}
                </A>
            )}

            {item.children.length ? (
                <>
                    <i className="icon-down_arrow"></i>
                    <div
                        className={`profile-menu nested-profile-menu ${subMenu ? 'active' : null}`}
                    >
                        <div className="profile-body">
                            <ul className="profile-item-listing">
                                {item.children.map((sublink, i) => (
                                    <React.Fragment key={i}>
                                        {sublink.isHidden ? null : (
                                            <li className={sublink.classToAdd}>
                                                <A
                                                    href={sublink.link}
                                                    onClick={
                                                        sublink.onClick
                                                            ? () => {
                                                                  sublink.onClick(
                                                                      navItemClick,
                                                                  )();
                                                              }
                                                            : null
                                                    }
                                                    className="subMenuSpanTag"
                                                    data-testid={sublink.title}
                                                >
                                                    {/* {console.log(sublink)} */}
                                                    {sublink.title}
                                                </A>
                                                {sublink.isNew ? (
                                                    <NewTag
                                                        className="sublink-new-tag"
                                                        withoutArrow
                                                    />
                                                ) : null}
                                            </li>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* ) : null} */}
                </>
            ) : null}
            {item.isNew ? <NewTag /> : null}
        </li>
    );
};
