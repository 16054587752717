import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useOutsideClickDetector } from '../../../utils/useOutsideClickDetector';
import { Overlay } from '../Overlay';
import './styles.scss';

export const Modal = props => {
    // const modalRef = useRef(null);
    // useOutsideClickDetector(modalRef, props.onClose);

    const styleType = props.styleType || 'original';

    useEffect(() => {
        document.body.style = 'overflow:hidden';
        return () => {
            document.body.style = 'overflow:auto';
        };
    }, []);

    return (
        <Overlay outsideClick={props.onClose}>
            <div
                className={`r-modal ${styleType} ${(props.classes || []).join(',')}`}
                // ref={modalRef}
                {...props.modalOptions}
            >
                {props.header ? (
                    <div className="r-modal-header">{props.header}</div>
                ) : null}
                {styleType === 'new' ? (
                    <img
                        className="r-modal-close"
                        src="/static/images/close.png"
                        alt="close"
                        onClick={props.onClose}
                        data-testid="btn-modal-close"
                    />
                ) : props.closeImg ? (
                    <img
                        className="r-modal-close"
                        src={props.closeImg}
                        alt=""
                        onClick={props.onClose}
                    />
                ) : (
                    <i
                        onClick={props.onClose}
                        className="r-modal-close icon-close_icon"
                    ></i>
                )}
                <div className="r-modal-content">{props.children}</div>
            </div>
        </Overlay>
    );
};

Modal.propTypes = {
    header: PropTypes.element,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.arrayOf(PropTypes.string),
    styleType: PropTypes.string,
    modalOptions: PropTypes.object,
};
