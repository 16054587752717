import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '../../../Overlay';
import { endpoints } from '../../../../../constants/endpoints';
import { sendEvent } from '../../../../../utils/sendEvent';
import { A } from '../../../A';
import { NewTag } from '../../../NewTag';
import { checkFeature } from '../../../../../utils/checkFeature';


import './styles.scss';

export const MobileNavBar = props => {
    const { profile, logout } = props;
    const [subMenu, setSubMenu] = useState(null);
    const [ contactUs, setContactUs] = useState(false)
    const [ exploreOpen, setExploreOpen] = useState(false)
    const [ basicInfoOpen, setBasicInfoOpen] = useState(false)

    function handleMenuChange(children = []) {
        if (children.length) {
            setSubMenu(children);
        }
    }

    function redirectProducts(tab_hash) {
        console.log("working")
        props.history.push(`/offerings?productHash=${tab_hash}`)
    }

    return (
        <Overlay outsideClick={props.onClose}>
            <div className="mobile-menu-container">
                <div className="mobile-menu-content">
                    <div className="mobile-logo mobile-menu-item left">
                        <div className="domain_logo header_item">
                            <a
                                onClick={() =>
                                    sendEvent('viewDashboard', props.logoLabel)
                                }
                                href={endpoints.dashboard}
                                className="logoLink"
                            >
                                <h1>{process.env.REACT_APP_BASE_DOMAIN}</h1>
                                <span>
                                    {process.env.REACT_APP_BASE_DOMAIN ===
                                    'hirist'
                                        ? '.tech'
                                        : '.com'}
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="close-mobile-menu mobile-menu-item right">
                        <span onClick={props.onClose}>
                            <i className="icon-close_icon"></i>
                        </span>
                    </div>
                    <div className="clearfix"></div>
                    <div className="mobile-profile-section mobile-menu-item menu-section {{hiddenActions}}">
                                <div className="profile-image left">
                                    <img
                                        alt="profile_pic"
                                        className="user_img"
                                        src={
                                            profile.img_link ||
                                            '/static/images/profilenoImage.png'
                                        }
                                    />
                                </div>
                                <div className="profile-info right">
                                    <p className="profile-name user_name" id="">
                                        {profile.name}
                                        {parseInt(profile.contact_verify) ===
                                        1 ? (
                                            <i
                                                className="icon-tick_circle"
                                                aria-hidden="true"
                                            ></i>
                                        ) : null}
                                    </p>
                                    <p className="profile-email user_email">
                                        {profile.email}
                                    </p>
                                    <a
                                        className="user-public-profile"
                                        href={profile.rurl}
                                    >
                                        <p className="user_public_profile">
                                            View Public Profile
                                            <i className="icon-right_arrow"></i>
                                        </p>
                                    </a>
                                </div>
                    </div>
                    <div className="user_profile_info_mob"  onClick={() => {setBasicInfoOpen(!basicInfoOpen)}}>
                        <span className="profile-hits_mob">
                            <span className="profile-views">
                                Viewed:{' '}
                            </span>
                            <span>{profile.hits}</span>
                            <span> times</span>
                        </span>
                        {!basicInfoOpen ? 
                            <i className="icon-right_arrow"></i>:
                            <i className="icon-down_arrow"></i>
                        }
                        <span className="profile-login_mob {{hiddenActionsDate}}">
                            <span className="lastLogin">
                                Last Login:{' '}
                            </span>
                            <span>{profile.lastLogin}</span>
                        </span>
                        
                        {   basicInfoOpen &&
                            <span className="profile-menu-basic-dets_mob {{hiddenActionsDate}}">
                                <div className="content-container-mob">
                                    <span className="actions basicAccount">
                                        <li className="basicPost">
                                            Basic Postings:
                                            <span className="white basic">{` Unlimited`}</span>
                                        </li>
                                    </span>
                                    <span className="actions basicAccount">
                                        <li>
                                            Premium Postings:
                                            <span className="basic">
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {` ${profile.availableCredits} `}
                                                </span>
                                                Credits
                                            </span>
                                        </li>
                                    </span>
                                </div>
                            </span>
                        }

                    </div>
                    {!subMenu || !subMenu.length ? (
                        <>
                            <div className="menu-link-listing mobile-menu-item menu-section navigationHeader {{hiddenActions}}">
                                <ul>
                                    {props.items.map((item, i) => (
                                        <React.Fragment key={i}>
                                            {item.isHidden ? null : (
                                                <A
                                                    href={
                                                        item.link &&
                                                        !(item.children || [])
                                                            .length
                                                            ? item.link
                                                            : null
                                                    }
                                                    onClick={() => {
                                                        handleMenuChange(
                                                            item.children,
                                                        );
                                                        if (
                                                            item.onClick &&
                                                            !(
                                                                item.children ||
                                                                []
                                                            ).length
                                                        ) {
                                                            item.onClick(
                                                                props.navItemClick,
                                                            )();
                                                        }
                                                    }}
                                                    className="menu-links"
                                                >
                                                    <li className="menu-list-item my-jobs">
                                                        {item.title}
                                                        {(item.children || [])
                                                            .length ? (
                                                            <i className="icon-right_arrow"></i>
                                                        ) : null}
                                                        {item.isNew ? (
                                                            <NewTag />
                                                        ) : null}
                                                    </li>
                                                </A>
                                            )}
                                        </React.Fragment>
                                    ))}

                                    <A
                                        href="#"
                                        onClick={() => {
                                            setContactUs(!contactUs)
                                        }}
                                        className="menu-links"
                                    >   
                                        <li className="menu-list-item my-jobs">
                                            Contact Us
                                            <i className={` ${contactUs ? "icon-down_arrow": "icon-right_arrow"}`}></i>
                                        </li>
                                        <div className={`abcabcabc contact_us_mobile_main ${contactUs ? "" : "abcabcabc_display"}`}>
                                            <div className="section">
                                                <div className="primary_header">Sales Enquiries</div>
                                                <div className="inner_section">
                                                    <div className="section_content">
                                                        <div className="contact_icon">
                                                            <i className="icon-telephone"></i>
                                                        </div>
                                                        <img src="" alt="" className="section_img" />
                                                        1800-103-7344 <span>(9:30 AM to 6:30 PM)</span>
                                                    </div>
                                                    <div className="section_content">
                                                        <div className="content_icon">
                                                            <i className="icon-email_envelope"></i>
                                                        </div>
                                                        sales@iimjobs.com
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section">
                                                <div className="primary_header">Customer Support</div>
                                                <div className="inner_section">
                                                    <div className="section_content">
                                                        <div className="content_icon">
                                                            <i className="icon-email_envelope"></i>
                                                        </div>
                                                        info@iimjobs.com
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </A>

                                    <A
                                        href="#"
                                        onClick={() => {
                                            setExploreOpen(!exploreOpen)
                                        }}
                                        className="menu-links"
                                    >   
                                        <li className="menu-list-item my-jobs">
                                            Explore
                                            <i className={` ${exploreOpen ? "icon-down_arrow": "icon-right_arrow"}`}></i>
                                        </li>
                                        <div className={`abcabcabc_explore ${exploreOpen ? "" : "abcabcabc_display"}`}>
                                            <ul className="section">
                                                <A
                                                    href="/offerings/?productHash=4"
                                                    
                                                    className="menu-links"
                                                >  
                                                    <li className="inner_section">
                                                        Premium Posting
                                                    </li>
                                                </A>
                                                <A
                                                    href="/offerings/?productHash=1"
                                                    
                                                    className="menu-links"
                                                >  
                                                    <li className="inner_section">
                                                        Search Resume
                                                    </li>
                                                </A>
                                                <A
                                                    href="/offerings/?productHash=2"
                                                    
                                                    className="menu-links"
                                                >  
                                                <li className="inner_section">
                                                    Calculus - Salary Benchmarking
                                                </li>
                                                </A>
                                                <A
                                                    href="/offerings/?productHash=5"
                                                    
                                                    className="menu-links"
                                                >  
                                                <li className="inner_section">
                                                    Employer Branding
                                                </li>
                                                </A>
                                                <A
                                                    href="/offerings/?productHash=3"
                                                    
                                                    className="menu-links"
                                                >  
                                                <li className="inner_section">
                                                    Maven - Diversity
                                                </li>
                                                </A>
                                               
                                            </ul>
                                        </div>
                                    </A>

                                </ul>
                            </div>

                            {/* <div className="mobile-profile-section mobile-menu-item menu-section {{hiddenActions}}">
                                <div className="profile-image left">
                                    <img
                                        alt="profile_pic"
                                        className="user_img"
                                        src={
                                            profile.img_link ||
                                            '/static/images/profilenoImage.png'
                                        }
                                    />
                                </div>
                                <div className="profile-info right">
                                    <p className="profile-name user_name" id="">
                                        {profile.name}
                                        {parseInt(profile.contact_verify) ===
                                        1 ? (
                                            <i
                                                className="icon-tick_circle"
                                                aria-hidden="true"
                                            ></i>
                                        ) : null}
                                    </p>
                                    <p className="profile-email user_email">
                                        {profile.email}
                                    </p>
                                    <a
                                        className="user-public-profile"
                                        href={profile.rurl}
                                    >
                                        <p className="user_public_profile">
                                            View Public Profile
                                            <i className="icon-right_arrow"></i>
                                        </p>
                                    </a>
                                </div>
                            </div> */}

                            {/* <div className="user_profile_info_mob">
                                <span className="profile-hits_mob">
                                    <span className="profile-views">
                                        Viewed:{' '}
                                    </span>
                                    <span>{profile.hits}</span>
                                    <span> times</span>
                                </span>
                                <span className="profile-login_mob {{hiddenActionsDate}}">
                                    <span className="lastLogin">
                                        Last Login:{' '}
                                    </span>
                                    <span>{profile.lastLogin}</span>
                                </span>
                            </div> */}

                            <div className="mobile-profile-actions mobile-menu-item menu-section {{hiddenActions}}">
                                <ul className="profile-item-listing">
                                    {/* <div className="content-container-mob">
                                        <span className="actions basicAccount">
                                            <li className="basicPost">
                                                Basic Postings:
                                                <span className="white basic">{` Unlimited`}</span>
                                            </li>
                                        </span>
                                        <span className="actions basicAccount">
                                            <li>
                                                Premium Postings:
                                                <span className="basic">
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {` ${profile.availableCredits} `}
                                                    </span>
                                                    Credits
                                                </span>
                                            </li>
                                        </span>
                                    </div> */}
                                    <a
                                        className="actions {{goBackToOldClass}}"
                                        href={endpoints.legacyRecruiterUrl}
                                    >
                                        <li className="legacyRecruiterMob legacyRecruiter">
                                            Access admin panel
                                        </li>
                                    </a>
                                    {
                                        checkFeature("accountSettings") ? (
                                            <a
                                                className="actions accountSettings"
                                                href={endpoints.recruiterProfile}
                                            >
                                                <li className="settings">
                                                    Account Settings
                                                </li>
                                            </a>
                                        ): null
                                    }
                                    <span className="actions">
                                        <li
                                            className="recruiterLogout"
                                            onClick={logout}
                                        >
                                            Logout
                                        </li>
                                    </span>
                                </ul>
                            </div>
                        </>
                    ) : null}

                    {subMenu && subMenu.length ? (
                        <div className="MyJobs menu-link-listing mobile-menu-item">
                            <ul>
                                <span
                                    className="menu-links"
                                    onClick={() => setSubMenu(null)}
                                >
                                    <li className="my-jobs-menu-back">
                                        <i className="icon-back_icon"></i>Back
                                    </li>
                                </span>
                                {subMenu.map(item => (
                                    <React.Fragment>
                                        {item.isHidden ? null : (
                                            <a
                                                onClick={
                                                    item.onClick
                                                        ? item.onClick(
                                                              props.navItemClick,
                                                          )
                                                        : null
                                                }
                                                href={item.link}
                                                className="menu-links myJobsLink"
                                            >
                                                <li className="my-jobs-menu">
                                                    {  
                                                        item.title
                                                    }
                                                    <ul className="nestedUl">
                                                    {item.nestedChildren &&
                                                        item.nestedChildren.children.map(childItem => (
                                                            <a href={childItem.link}> <li className = {childItem.classToAdd} key={childItem.title}>{childItem.title}</li></a>
                                                        ))
                                                    }</ul>
                                                    {item.isNew ? (
                                                        <NewTag
                                                            withoutArrow
                                                            className="sublink-new-tag"
                                                        />
                                                    ) : null}
                                                </li>
                                            </a>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                </div>
            </div>
        </Overlay>
    );
};

MobileNavBar.propTypes = {
    onClose: PropTypes.func.isRequired,
    items: PropTypes.array,
    navItemClick: PropTypes.func.isRequired,
};
